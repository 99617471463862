<template>
    <v-dialog v-model="dialogModel" max-width="500px">
		<v-card>
			<v-card-title class="red white--text">
                <v-icon class="white--text mr-3">mdi-alert</v-icon>
				Suppression
			</v-card-title>
            <v-card-text class="py-5">
                <v-spacer/>
				{{ text }}
				<v-spacer/>
            </v-card-text>
			<v-card-actions class="pb-4">
				<v-spacer/>
				<ButtonCancel :condition="false" v-on:clicked="$emit('close')"/>
				<ButtonValidate text="confirmer" :condition="false" v-on:clicked="$emit('confirm')"/>
				<v-spacer/>
			</v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import ButtonValidate from "@/components/buttons/ButtonValidate";
import ButtonCancel from "@/components/buttons/ButtonCancel";

export default {
    name: "ConfirmSuppressionDialog",
    props: {
        dialogModel: {
            type: Boolean,
            default: false
        },
        text: {
            type: String
        }
    },
    components: {
        ButtonValidate,
        ButtonCancel,
    }
}
</script>