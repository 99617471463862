<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-spacer></v-spacer>
        <v-card-title class="justify-center">
          Création d'utilisateur
        </v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form ref="formNewUser" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="formNewUser.login"
                  :counter="16"
                  :rules="rulesNewUser.login"
                  v-on:keyup.enter="createNewUser"
                  label="Login*"
                >
                </v-text-field>
              </v-col>
              <v-col class="pa-0" cols="12">
                <v-text-field
                  v-model="formNewUser.email"
                  label="Email*"
                  :counter="32"
                  :rules="rulesNewUser.email"
                  v-on:keyup.enter="createNewUser"
                ></v-text-field>
              </v-col>
              <v-col class="pa-0" cols="12">
                <v-text-field
                  v-model="formNewUser.password"
                  label="Mot de passe"
                  :rules="rulesNewUser.password"
                  type="password"
                  v-on:keyup.enter="createNewUser"
                ></v-text-field>
              </v-col>
              <v-col class="pa-0" cols="12">
                <v-text-field
                  v-model="formNewUser.rePassword"
                  label="Confirmation mot de passe"
                  :rules="rulesNewUser.rePassword"
                  type="password"
                  v-on:keyup.enter="createNewUser"
                ></v-text-field>
              </v-col>
              <v-col class="pa-0" cols="12" v-if="companies">
                <v-autocomplete
                  v-model="formNewUser.companies"
                  multiple
                  label="Entreprises*"
                  :rules="rulesNewUser.companies"
                  :items="formatCompanies"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-autocomplete
                  v-model="formNewUser.role"
                  :items="roles"
                  label="Role*"
                  :rules="rulesNewUser.role"
                ></v-autocomplete>
                <!-- V-SELECT THERE... -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <ButtonCancel
            v-on:clicked="close(false)"/>
            <ButtonValidate
            v-on:clicked="createNewUser"/>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonValidate from "@/components/buttons/ButtonValidate";
import ButtonCancel from "@/components/buttons/ButtonCancel";

import {
  VToolbar,
  VSpacer,
  VContainer,
  VCardActions,
  VAutocomplete,
  VCol,
  VRow,
  VTextField,
  VCardText,
  VForm,
  VCardTitle,
  VCard,
  VDialog,
} from "vuetify/lib";

export default {
  name: "UserCreate",
  components: {
    VToolbar,
    VSpacer,
    VContainer,
    VCardActions,
    VAutocomplete,
    VCol,
    VRow,
    VTextField,
    VCardText,
    VForm,
    VCardTitle,
    VCard,
    VDialog,
    ButtonValidate,
    ButtonCancel
  },
  props: {
    dialog: Boolean,
    companies: Array,
  },

  data() {
    const defaultNewUser = Object.freeze({
      login: "",
      email: "",
      password: "",
      rePassword: "",
      companies: [],
      role: "",
    });
    return {
      formNewUser: Object.assign({}, defaultNewUser),
      rulesNewUser: null,
      roles: [],
    };
  },
  created() {
    this.rulesNewUser = this.rulesCreateFormUser;
    this.roles = this.getRoles;
  },
  computed: {
    formatCompanies() {
      return this.companies.map((item) => item.name);
    },
    getRoles() {
      if (this.$store.getters.getRole === "avocat") return ["Client"];
      else return ["Avocat", "Client"];
    },
    rulesCreateFormUser() {
      const rules = {
        login: [],
        password: [],
        rePassword: [],
        email: [],
        companies: [],
        role: [],
      };
      const notEmpty = (v) => (v || "").length > 0 || "Champ requis";
      const lenLogin = (v) => v.length < 16 || "16 characters maximum";
      const charLogin = (v) =>
        /^[a-zA-Z0-9-_.]+$/.test(v) || "Character valide a-z A-Z 0-9";
      rules.login.push(notEmpty);
      rules.login.push(lenLogin);
      rules.login.push(charLogin);
      rules.password.push(notEmpty);
      rules.rePassword.push(notEmpty);
      rules.email.push(notEmpty);
      rules.companies.push(notEmpty);
      rules.role.push(notEmpty);
      const lenEmail = (v) => v.length < 64 || "64 characters maximum";
      rules.email.push(lenEmail);
      const email_rule = (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail invalide";
      rules.email.push(email_rule);

      const lenPassword = (v) => v.length >= 8 || "8 characters minimum";
      rules.password.push(lenPassword);
      const validPassword = () =>
        this.formNewUser.password === this.formNewUser.rePassword ||
        "Les mots de passe ne correspondent pas";
      rules.rePassword.push(validPassword);
      return rules;
    },
  },
  methods: {
    createNewUser() {
      if (this.$refs.formNewUser.validate()) {
        let companiesObj = this.companies.filter((brand) => {
          return this.formNewUser.companies.indexOf(brand.name) > -1;
        });
        let ids = companiesObj.map(({ id }) => id);
        this.formNewUser.companies = ids;

        this.formNewUser.role = this.formNewUser.role.toLowerCase();
        this.$api
          .post("/users", this.formNewUser)
          .then(() => {
            this.$refs.formNewUser.reset();
            this.close(true);
          })
          .catch((err) => {
            if (err.response.status === 409) this.loginAlreadyTaken();
            this.close(false);
          })
          .finally(() => {
            // this.$refs.formNewUser.reset()
          });
      }
    },
    close(val) {
      this.$emit("closeUserCreate", val);
    },
    loginAlreadyTaken() {
      this.$emit("loginError");
    },
  },
};
</script>

<style>
</style>