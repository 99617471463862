<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container fluid>
    <NavBarLeft />
    <v-data-table
    v-if="domains"
	class="row-pointer"
    :headers="domainsHeader"
    :items="domainsData"
    item-key="id"
    :expanded.sync="expanded"
    :loading="loading"
    :footer-props="pageProps"
    :search="search"
    :custom-sort="customSort"
	@click:row="handleClickRow">
	
<!-- TITLE -->
		<template v-slot:top>
			<v-toolbar flat>
				<DashboardTitle
				:text="'CORBEILLE ' + brand.name"/>
			</v-toolbar>
			
			<p style="width: 20%" class="ml-4">
				<v-text-field
				v-model="search"
				append-icon="fas fa-search"
				label="Recherche"
				hide-details/>
			</p>
			<p class="ml-4 font-italic text-left caption">
				Le tableau est trié par défaut par date de signalement.
			</p>
		</template>

<!-- HEADER -->
		<template v-for="header in domainsHeader" v-slot:[`header.${header.value}`]="{ header }">
			<v-menu
			v-if="header.filterable"
			:key="header.value"
			offset-y
			:close-on-content-click="false">
				<template v-slot:activator="{ on, attrs }">						
						<v-btn x-small elevation="0" v-bind="attrs" v-on="on" :color="filters[header.value] ? 'primary' : ''">
							{{ header.text }}
						</v-btn>
				</template>
				
				<div style="background-color: white; width: 280px">
					<v-text-field
					v-model="filters[header.value]"
					clearable class="pa-4" type="text"
					:label="header.filterLabel"/>
				</div>
			</v-menu>
		</template>

<!-- VALUES -->
		<template v-slot:item.domain="{ item }">
			<span v-if="holderSize(item.domain)">
				{{ holderLimit(item.domain) }}
			</span>
			<span v-else>{{ item.domain ? item.domain : "-" }}</span>
		</template>
		
		<template v-slot:item.color="{ item }">
			<v-tooltip left>
				<template v-slot:activator="{ on, attrs }">
					<v-chip
					:color="getColor(item.color)" dark small
					v-bind="attrs" v-on="on"/>
				</template>
				
				<span>{{ item.color ? item.color : "Autres" }}</span>
			</v-tooltip>
		</template>
		
		<template v-slot:item.holder="{ item }">
			<span v-if="holderSize(item.holder)">
			{{ holderLimit(item.holder) }}
			</span>
			<span v-else>
			{{ item.holder }}
			</span>
		</template>

		<template v-slot:item.follow="{ item }">
			<span v-if="holderSize(item.follow)">
				{{ holderLimit(item.follow) }}
			</span>
			<span v-else>{{ item.follow ? item.follow : "-" }}</span>
		</template>

		<template v-slot:item.date_report="{ item }">
			<span>{{ formatDate(item.date_report) }}</span>
		</template>

		<template v-slot:item.instruction="{ item }">
			<span v-if="holderSize(item.instruction)">
				{{ holderLimit(item.instruction) }}
			</span>
			<span v-else>{{ item.instruction ? item.instruction : "-" }}</span>
		</template>

		<template v-slot:item.reco_scan="{ item }">
			<span v-if="holderSize(item.reco_scan)">
				{{ holderLimit(item.reco_scan) }}
			</span>
			<span v-else>{{ item.reco_scan ? item.reco_scan : "-" }}</span>
		</template>

		<template v-slot:item.usage="{ item }">
			<span v-if="holderSize(item.usage)">
				{{ holderLimit(item.usage) }}
			</span>
			<span v-else>{{ item.usage ? item.usage : "-" }}</span>
		</template>

		<template v-slot:item.edit="{ item }">
      <v-badge
      :value="!item.visible"
      color="red" dot
      offset-x="10"
      offset-y="10">
        <HandleDomainMenu
        :isAvocat="isAvocat"
        :isAdmin="isAdmin"
        :visible="item.visible"
        bin
        v-on:view="viewDomain(item)"
        v-on:delete="deleteDomain(item)"
        v-on:unbin="unbinDomain(item)"/>
      </v-badge>
		</template>

<!-- EXPANSIONS -->
		<template v-slot:expanded-item="{ item }">
			<td :colspan="item.length" class="grey lighten-5 pa-0" style="vertical-align: top; border-bottom: 1px solid" v-for="(col, key) in domainsHeader" :key="key">
				<v-sheet flat class="transparent px-4"  :max-length="item.length" v-if="item[col.value] && ['date_report', 'data-table-expand', 'edit', 'color', 'holder'].includes(col.value) == false">
					<v-card-title style="font-size: 12px" class="pa-0 pt-0 pb-0 pl-0"> {{ col.text }} </v-card-title>
					<v-card-text style="font-size: 12px" class="pa-0 pt-0"> {{ item[col.value] }} </v-card-text>
				</v-sheet>
			</td>
		</template>
    </v-data-table>

    <v-fab-transition>
      <v-btn fixed right bottom fab large
      style="margin-bottom: 60px; margin-right: 20px;"
      v-show="windowTop"
      v-scroll="onScroll"
      @click="$vuetify.goTo(0, options)" color="primary">
        <v-icon>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="dialogDelete" max-width="500px">
		<v-card class="py-4">
			<v-card-title>
				<v-spacer/>
				<DashboardTitle
				v-if="deletedItem"
				:text="'Supprimer le domaine ' + deletedItem.domain + ' ?'"/>
				<v-spacer/>
			</v-card-title>
			<v-card-actions>
				<v-spacer/>
				<ButtonCancel :condition="false" v-on:clicked="closeDeleteConfirm"/>
				<ButtonValidate :condition="false" v-on:clicked="deleteDomainConfirm"/>
				<v-spacer/>
			</v-card-actions>
      </v-card>
    </v-dialog>

    <EditDomain
    v-if="viewItem"
    :noEdition="true"
    :dialog="viewDomainToggle"
    :brand="this.$route.params.id"
    :domain="viewItem"
    v-on:closeDomain="viewDomainToggle = false"/>

  </v-container>
</template>

<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import EditDomain from "../components/EditDomain.vue";
// import EditionDomainNewMenuVue from '../components/EditionDomainNewMenu.vue';

import MonitoringHeader from "../helpers/MonitoringHeader";
import MonitoringUserHeader from "../helpers/MonitoringUserHeader";
import FilterMonitoring from "../mixins/FilterMonitoring";

import DashboardTitle from "@/components/texts/DashboardTitle";
import ButtonCancel from "@/components/buttons/ButtonCancel";
import ButtonValidate from "@/components/buttons/ButtonValidate";

import HandleDomainMenu from "@/components/menus/HandleDomainMenu";

import MapStore from "@/mixins/MapStore";

import {
  VDialog,
  VCard,
  VToolbar,
  VSpacer,
  VCardActions,
  VTextField,
  VBtn,
  VCardTitle,
  VDataTable,
  VIcon,
  VTooltip,
  VMenu,
  VChip,
  VContainer,
  VCardText,
} from "vuetify/lib";


export default {
  name: "Monitoring",
  components: {
    NavBarLeft,
    // EditionDomainNewMenuVue,
    EditDomain,
    VDialog,
    VCard,
    VToolbar,
    VSpacer,
    VCardActions,
    VTextField,
    VBtn,
    VCardTitle,
    VDataTable,
    VIcon,
    VTooltip,
    VMenu,
    VChip,
    VContainer,
    VCardText,
    DashboardTitle,
	HandleDomainMenu,
	ButtonCancel,
	ButtonValidate
  },
  mixins: [ MapStore, FilterMonitoring ],
  data() {
    return {
		expanded: [],
        viewItem: null,
        viewDomainToggle: false,
		editDomainToggle: false,
		editDomainToggleUser: false,
		snackBarNewDomain: false,
		snackBarDeleteDomain: false,
    snackBarDeleteDomainError: false,
		snackBarEditDomain: false,
		snacbkarEditAsUser: false,
		brand: null,
		domains: null,
		editedDomain: null,
		editedItemUser: null,
		deletedItem: null,
		dialogDelete: false,
		windowTop: false,
		more_informations: [
			{
				"label": "Informations titulaire",
				"key": "holder"
			},
			{
				"label": "Exploitation",
				"key": "usage"
			},
			{
				"label": "Recommandations Scan",
				"key": "reco_scan"
			},
			{
				"label": "Instructions client",
				"key": "instruction"
			},
			{
				"label": "Suivi",
				"key": "follow"
			}
		]
    };
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch('get', {
      route: this.api.brands,
      id: id,
      params: { company_id: this.$store.state.company }
    }).then((data) => {
        this.brand = data[0];
        this.getDomains();
    }).catch((error) => {
      if (error.response.status === 403) this.$router.push("/brands");
    })
  },
  computed: {
    options () {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      }
    },
    domainsData() {
      let conditions = [];
      let funcs = this.getFiltersFunc();
      for (const property in this.filters) {
        if (this.filters[property]) conditions.push(funcs[property]);
      }
      if (conditions.length > 0) {
        return this.domains.filter((domain) => {
          return conditions.every((condition) => {
            return condition(domain);
          });
        });
      }
      return this.domains;
    },
    pageProps() {
      return { itemsPerPageOptions: [100, 50, 15] };
    },
    domainsHeader() {
      let val = this.isAdmin ? true : false;
      if (val) {
        return MonitoringHeader();
      } else {
        return MonitoringUserHeader();
      }
    },
	},
	methods: {
        unbinDomain(item) {
            let id = this.$route.params.id;
            let route = this.api.domains;
            let url = "/brands/" + id + "/domains/" + item.id
      
            this.$store.dispatch('put', {
                url: url,
                route: route,
                id: item.id,
                data: { deleted_tmp: false }
            }).catch((err) => {
                if (err.response.status === 403) this.$router.push("/brands");
            }).finally(() => {
                this.dialogDelete = false;
                this.deletedItem = null;
                this.getDomains();
            })
        },
        finalBinDomain(item) {
            let id = this.$route.params.id;
            let route = this.api.domains;
            let url = "/brands/" + id + "/domains/" + item.id
      
            this.$store.dispatch('delete', {
                url: url,
                route: route,
                id: item.id,
                data: { deleted_tmp: false }
            }).catch((err) => {
                if (err.response.status === 403) this.$router.push("/brands");
            }).finally(() => {
                this.dialogDelete = false;
                this.deletedItem = null;
                this.getDomains();
            })
        },
        viewDomain(item) {
            this.viewItem = item;
            this.viewDomainToggle = true;
        },
        closeView() {
            (this.viewItem = null), (this.viewDomainToggle = false);
        },
		handleClickRow(event) {
			let index = this.expanded.indexOf(event, 0);
	
			if (index != -1) {
				this.expanded.splice(index, 1);
			} else {
				this.expanded.push(event);
			}
		},
	onScroll () {
		const top = (
        window.pageYOffset ||
        document.documentElement.offsetTop ||
        0
      )
      this.windowTop = top > 300
    },
    closeDomainAsUser() {
      this.editDomainToggleUser = false;
      this.editedItemUser = null;
    },
    closeDomain() {
      this.editDomainToggle = false;
      this.editedDomain = null;
    },
    closeDeleteConfirm() {
      this.dialogDelete = false;
      this.deletedItem = null;
    },
    deleteDomain(item) {
      this.dialogDelete = true;
      this.deletedItem = item;
    },
    editDomain(item) {
      this.editedDomain = item;
      this.editDomainToggle = true;
    },
    deleteDomainConfirm() {
      let id = this.$route.params.id;
      let route = this.api.brands;
      route.url = this.api.brands.url + "/" + id + this.api.domains.url;
      
      this.$store.dispatch('delete', {
        route: route,
        id: this.deletedItem.id,
        data: { archive: true }
      }).catch((err) => {
        if (err.response.status === 403) this.$router.push("/brands");
      }).finally(() => {
        this.dialogDelete = false;
        this.deletedItem = null;
        this.getDomains();
      })
    },
    getDomains() {
        let id = this.$route.params.id;
        this.$store.state.loading = true;

        this.$api
        .get("/brands/" + id + "/domains", {params: { company_id: this.$store.state.company, deleted: true }})
        .then((res) => {
            this.domains = res.data;
        })
        .catch((err) => {
            if (err.response.status === 403) this.$router.push("/brands");
        })
        .finally(() => {
            this.$store.state.loading = false;
        });
    },
    holderLimit(item) {
      if (item) {
        if (item.length > 24) return item.slice(0, 24) + "...";
        return item;
      }
      return "-";
    },
    holderSize(item) {
      if (item) {
        if (item.length > 24) return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.removePad {
  padding: 0;
}
</style>