<template>
    <v-btn
    depressed color="error"
    class="justify-center mr-1"
    :disabled="disabled"
    @click="$emit('clicked')">
        <span style="font-size: 12px !important;">
            {{ text ? text : "Annuler" }}
        </span>
    </v-btn>
</template>

<script>
export default {
    name: "ButtonCancel",
    props: {
        text: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>