<template>
  <v-dialog width="500" v-model="dialog" persistent>
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Édition</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="saveNewPrice"> Sauvegarder </v-btn>
        </v-toolbar-items>
        <v-btn dark icon @click="closeNewPrice">
          <v-icon dark>fas fa-arrow-left</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-if="form" ref="form" lazy-validation>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-text-field
                v-model="form.monitoring"
                label="Monitoring"
                suffix="€ HT"
                :rules="[
                  (v) => (v.toString() || '').length > 0 || 'Champ requis',
                  (v) => /^\d+$/.test(v) || 'Caractères invalides',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.anonym"
                label="Levée d'anonymat"
                suffix="€ HT"
                :rules="[
                  (v) => (v.toString() || '').length > 0 || 'Champ requis',
                  (v) => /^\d+$/.test(v) || 'Caractères invalides',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.notice"
                label="Mise en demeure"
                suffix="€ HT"
                :rules="[
                  (v) => (v.toString() || '').length > 0 || 'Champ requis',
                  (v) => /^\d+$/.test(v) || 'Caractères invalides',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.notif"
                label="Notifications"
                suffix="€ HT"
                :rules="[
                  (v) => (v.toString() || '').length > 0 || 'Champ requis',
                  (v) => /^\d+$/.test(v) || 'Caractères invalides',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.extra"
                label="Procédure extra judiciaire"
                suffix="€ HT"
                :rules="[
                  (v) => (v.toString() || '').length > 0 || 'Champ requis',
                  (v) => /^\d+$/.test(v) || 'Caractères invalides',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.backorder"
                label="Backorder"
                suffix="€ HT"
                :rules="[
                  (v) => (v.toString() || '').length > 0 || 'Champ requis',
                  (v) => /^\d+$/.test(v) || 'Caractères invalides',
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.eval"
                label="Évaluation financière"
                suffix="€ HT"
                :rules="[
                  (v) => (v.toString() || '').length > 0 || 'Champ requis',
                  (v) => /^\d+$/.test(v) || 'Caractères invalides',
                ]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VCard,
  VToolbar,
  VSpacer,
  VDialog,
  VRow,
  VCol,
  VBtn,
  VIcon,
  VForm,
  VTextField,
  VCardText,
  VToolbarItems,
  VToolbarTitle,
} from "vuetify/lib";

export default {
  name: "PriceEdit",
  components: {
    VCard,
    VDialog,
    VToolbar,
    VSpacer,
    VRow,
    VCol,
    VBtn,
    VForm,
    VIcon,
    VTextField,
    VCardText,
    VToolbarItems,
    VToolbarTitle,
  },
  props: {
    brand: Number,
    dialog: Boolean,
    prices: Object,
  },
  data() {
    return {
      defaultForm: null,
      form: null,
      rules: null,
      editMonitToggle: true,
    };
  },
  created() {
    this.defaultForm = Object.freeze(this.prices);
    this.form = Object.assign({}, this.defaultForm);
  },
  mounted() {},
  methods: {
    saveNewPrice() {
      if (this.$refs.form.validate()) {
        this.$api
          .put("/brands/" + this.brand + "/prices", this.form)
          .then(() => {
            this.$emit("editSuccess");
          })
          .catch(() => {
            this.$emit("editError");
          })
          .finally(() => {
            this.$emit("closeEdit");
          });
      }
    },
    closeNewPrice() {
      this.$emit("closeEdit");
    },
  },
};
</script>

<style>
</style>