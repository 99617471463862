<template>
    <v-container fluid>
        <h1>La page que vous recherchez n'existe pas.</h1>
    </v-container>
</template>

<script>

export default {
    name: "PageNotFound"
}
</script>

<style>

</style>