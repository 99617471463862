import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#0078a3",
				secondary: "#3ba6a6",
				tertiary: "#8aa6a3"
			}
		}
	},
	icons: {
		iconfont: 'mdi'
	},
	lang: {
		locales: { fr },
		current: 'fr',
	},
});
