<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-spacer></v-spacer>
        <v-card-title class="justify-center">
          Édition de {{ user.login }}
        </v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form ref="formEditUser" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-autocomplete
                  v-model="formEditUser.companies"
                  :items="formatCompanies"
                  multiple
                  label="Marques"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" dark @click="close(false)"
            >Annuler</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed dark @click="editUser"
            >Confirmer</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VToolbar,
  VSpacer,
  VBtn,
  VContainer,
  VCardActions,
  VCol,
  VRow,
  VAutocomplete,
  VForm,
  VCardText,
  VCard,
  VCardTitle,
  VDialog,
} from "vuetify/lib";

export default {
  name: "UserCreate",
  components: {
    VToolbar,
    VSpacer,
    VBtn,
    VContainer,
    VCardActions,
    VCol,
    VRow,
    VAutocomplete,
    VForm,
    VCardText,
    VCard,
    VCardTitle,
    VDialog,
  },
  props: {
    dialog: Boolean,
    user: Object,
    companies: Array,
  },
  data() {
    const defaultEditUser = Object.freeze({
      // login: '',
      // email: '',
      // password: '',
      // rePassword: '',
      companies: [],
      // role: '',
    });
    return {
      formEditUser: Object.assign({}, defaultEditUser),
      rulesNewUser: null,
    };
  },
  created() {
    this.rulesNewUser = this.rulesCreateFormUser;
    this.roles = this.getRoles;
    this.formEditUser.companies = this.user.companies;
  },
  computed: {
    formatCompanies() {
      return this.companies.map((item) => item.name);
    },
  },
  methods: {
    editUser() {
      if (this.$refs.formEditUser.validate()) {
        let companiesObj = this.companies.filter((company) => {
          return this.formEditUser.companies.indexOf(company.name) > -1;
        });
        let ids = companiesObj.map(({ id }) => id);
        this.formEditUser.companies = ids;

        this.$api
          .put("/users/" + this.user.id, this.formEditUser)
          .then(() => {
            this.close(true);
          })
          .catch(() => {
            this.errorEdit();
            this.close(false);
          })
          .finally(() => {
            this.$refs.formEditUser.reset();
          });
      }
    },
    close(val) {
      this.$emit("closeUserEdit", val);
    },
    errorEdit() {
      this.$emit("editError");
    },
  },
};
</script>
