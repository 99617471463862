<template>
    <v-card flat>
        <v-card-title class="dashboard-title ml-0 pl-0">
            <v-icon v-if="icon" small class="mr-2"> {{ icon }} </v-icon>
            <span class="font-weight-bold black--text">{{ text }}</span>
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>

.dashboard-title {
    font-size: 20px;
}

</style>
