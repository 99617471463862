<template>
  <v-container fluid>
    <NavBarLeft />
    <v-card>
      <v-toolbar flat>
        <DashboardTitle
        v-if="brand"
        :text="'REPORTING ' + brand[0].name"/>
    
        <v-spacer></v-spacer>
        <v-btn
          v-if="isAdmin"
          color="primary"
          dark fab small
          elevation="0"
          @click="priceEditToggle = true"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title v-if="brand" class="pb-10"> </v-card-title>
      <v-row>
        <v-col cols="6">
          <line-chart
            v-if="statsFormat"
            :datasets="statsFormat"
            :options="{ maintainAspectRatio: false }"
          ></line-chart>
        </v-col>
        <v-col cols="4">
          <v-data-table
            v-if="prices && stats"
            :headers="headers"
            :items="fillPrice()"
            :hide-default-footer="true"
          >
            <template slot="body.append">
              <tr class="pink--text">
                <th class="title">Total Honoraire</th>
                <th></th>
                <th></th>
                <th>{{ getTotal() }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <PriceEdit
      v-if="priceEditToggle"
      :brand="brand_id"
      :dialog="priceEditToggle"
      :prices="prices"
      @editSuccess="editSuccess"
      @editError="snacbkarEditError = true"
      @closeEdit="priceEditToggle = false"
    />
    <v-snackbar v-model="snacbkarEditSuccess" timeout="2000" color="success">
      Modifications enregistrées
    </v-snackbar>
    <v-snackbar v-model="snacbkarEditError" timeout="2000" color="error">
      Erreur lors de l'édition
    </v-snackbar>
  </v-container>
</template>

<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import LineChart from "../charts/LineChart.vue";
import PriceEdit from "@/components/PriceEdit.vue";

import DashboardTitle from "@/components/texts/DashboardTitle";

import {
  VCard,
  VToolbar,
  VSpacer,
  VRow,
  VCol,
  VBtn,
  VCardTitle,
  VDataTable,
  VIcon,
  VContainer,
  VSnackbar,
} from "vuetify/lib";

export default {
  name: "Reporting",
  components: {
    NavBarLeft,
    LineChart,
    PriceEdit,
    VCard,
    VToolbar,
    VSpacer,
    VRow,
    VCol,
    VBtn,
    VCardTitle,
    VDataTable,
    VIcon,
    VContainer,
    VSnackbar,
    DashboardTitle
  },
  data() {
    return {
      stats: null,
      statsFormat: null,
      prices: null,
      brand: null,
      brand_id: null,
      datacollection: null,
      priceCollection: null,
      priceEditToggle: null,
      snacbkarEditSuccess: null,
      snacbkarEditError: null,
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Prix/u €", value: "price" },
        { text: "Qte", value: "qte" },
        { text: "Total € HT", value: "total" },
      ],
      datasets: [],
      isAdmin: false,
    };
  },
  computed: {},
  created() {
    this.getBrand();
    this.getStats();
    this.getPrices();
    if (this.$store.getters.getUser.role != "client") this.isAdmin = true;
  },
  methods: {
    editSuccess() {
      this.getStats();
      this.getPrices();
      this.snacbkarEditSuccess = true;
    },
    fillData(stats) {
      return {
        labels: [
          "Monitoring",
          "Levée d'anonymat",
          "Mise en demeure",
          "Notifications",
          "Procédure extra judiciaire",
          "Backorder",
          "Évaluation financière",
        ],
        datasets: [
          {
            label: "",
            data: [
              parseInt(stats.totalmonit) <= 0 ? -1 : stats.totalmonit,
              parseInt(stats.totalanonyme) <= 0 ? -1 : stats.totalanonyme,
              parseInt(stats.totalnotice) <= 0 ? -1 : stats.totalnotice,
              parseInt(stats.totalnotif) <= 0 ? -1 : stats.totalnotif,
              parseInt(stats.totalprocedureextra) <= 0
                ? -1
                : stats.totalprocedureextra,
              parseInt(stats.totalbackorder) <= 0 ? -1 : stats.totalbackorder,
              parseInt(stats.totalevalfinanciere) <= 0
                ? -1
                : stats.totalevalfinanciere,
            ],
            backgroundColor: [
              "#78909c",
              "#7e57c2",
              "#ffee58",
              "#ef5350",
              "#ffa726",
              "#66bb6a",
              "#bdbdbd",
            ],
            hoverOffset: 4,
          },
        ],
      };
    },
    getTotal() {
      this.total = 0;
      if (this.prices && this.stats) {
        this.total += this.prices.monitoring * parseInt(this.stats.totalmonit);
        this.total += this.prices.anonym * parseInt(this.stats.totalanonyme);
        this.total += this.prices.notice * parseInt(this.stats.totalnotice);
        this.total += this.prices.notif * parseInt(this.stats.totalnotif);
        this.total +=
          this.prices.extra * parseInt(this.stats.totalprocedureextra);
        this.total += this.prices.backorder * parseInt(this.stats.totalbackorder);
        this.total += this.prices.eval * parseInt(this.stats.totalevalfinanciere);
      }

      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(this.total);
    },
    fillPrice() {
      return [
        {
          name: "Monitoring",
          price: this.prices ? this.prices.monitoring : 0,
          qte: this.stats ? this.stats.totalmonit : 0,
          total: this.prices ? this.prices.monitoring * parseInt(this.stats.totalmonit) : 0,
        },
        {
          name: "Levée d'anonymat",
          price: this.prices ? this.prices.anonym : 0,
          qte: this.stats ? this.stats.totalanonyme : 0,
          total: this.prices ? this.prices.anonym * parseInt(this.stats.totalanonyme) : 0,
        },
        {
          name: "Mise en demeure",
          price: this.prices ? this.prices.notice : 0,
          qte: this.stats ? this.stats.totalnotice : 0,
          total: this.prices ? this.prices.notice * parseInt(this.stats.totalnotice) : 0,
        },
        {
          name: "Notifications",
          price: this.prices ? this.prices.notif : 0,
          qte: this.stats ? this.stats.totalnotif : 0,
          total: this.prices ? this.prices.notif * parseInt(this.stats.totalnotif) : 0,
        },
        {
          name: "Procédure extra judiciaire",
          price: this.prices ? this.prices.extra : 0,
          qte: this.stats ? this.stats.totalprocedureextra : 0,
          total: this.prices ? this.prices.extra * parseInt(this.stats.totalprocedureextra) : 0,
        },
        {
          name: "Backorder",
          price: this.prices ? this.prices.backorder : 0,
          qte: this.stats ? this.stats.totalbackorder : 0,
          total: this.prices ? this.prices.backorder * parseInt(this.stats.totalbackorder) : 0,
        },
        {
          name: "Évaluation financière",
          price: this.prices ? this.prices.eval : 0,
          qte: parseInt(this.stats ? this.stats.totalevalfinanciere : 0),
          total: this.prices ? this.prices.eval * parseInt(this.stats.totalevalfinanciere) : 0,
        },
      ];
    },
    getStats() {
      let brand = this.$route.params.id;
      this.$api
        .get("/brands/" + brand + "/counts")
        .then((res) => {
          this.stats = res.data[0];
          this.statsFormat = this.fillData(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPrices() {
      let brand = this.$route.params.id;
      this.$api
        .get("/brands/" + brand + "/prices")
        .then((res) => {
          this.prices = res.data[0];
          this.priceCollection = this.fillPrice();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBrand() {
      let id = this.$route.params.id;
      this.$api
        .get("/brands/" + id)
        .then((res) => {
          this.brand = res.data;
          this.brand_id = res.data[0].id;
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    },
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
