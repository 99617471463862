import Vue from 'vue'
import store from '../store'
import router from '../router';

const axiosConfig = {
  createAxiosResponseInterceptor() {
    const interceptor = Vue.prototype.$api.interceptors.response.use(
      response => response,
      error => {

        if (error.response.status !== 401) {
          return Promise.reject(error);
        }
        /* 
        * When response code is 401, try to refresh the token.
        * Eject the interceptor so it doesn't loop in case
        * token refresh causes the 401 response
        */
        Vue.prototype.$api.interceptors.response.eject(interceptor);
        const token = store.getters.getRefreshToken;
        return Vue.prototype.$api.post('/auth/token', {
          'refreshToken': token
        }).then(res => {
          store.dispatch('storeToken', res.data)
          error.response.config.headers["x-access-token"] = res.data
          return Vue.prototype.$api(error.response.config)
        }).catch(() => {
          store.dispatch('logout', store.getters.getId)
            .then(() => {
              router.push('/')
            })
          return Promise.reject(error);
        })
          .finally(this.createAxiosResponseInterceptor);
      }
    );
  },
  createAxiosUseInterceptor() {
    Vue.prototype.$api.interceptors.request.use(function (config) {
      const token = store.getters.getToken;
      if (token) {
        config.headers["x-access-token"] = token
      }
      return config
    }, function (err) {
      return Promise.reject(err);
    });
  }
}



export default axiosConfig;