import Vue from 'vue'
import Vuex from 'vuex'

import api from './api.js'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        api,
    },
    state: {
        user: null,
        token: null,
        refreshToken: null,
        brand: null,
        expireIn: null,
        company: null,
        loading: false,
        snackbar: {
            model: false,
            status: "",
            msg: ""
        }
    },
    mutations: {
        INITIALISATION_STORE(state) {
            if(localStorage.getItem('token')){
                state.token = localStorage.getItem('token')
                var base64Payload = state.token.split('.')[1];
                var payload = Buffer.from(base64Payload, 'base64');
                var data = JSON.parse(payload.toString());
                state.expireIn = data.exp
                state.user = {
                    id: data.id,
                    login: data.login,
                    email: data.email,
                    role: data.role_id
                }
            }
            if(localStorage.getItem('refreshToken')){
                state.refreshToken = localStorage.getItem('refreshToken')
            }
            if (localStorage.getItem('brand')) {
                state.brand = localStorage.getItem('brand')
            }
            if (localStorage.getItem('company')) {
                state.company = localStorage.getItem('company')
            }
        },
        storeUser (state, tokens) {
            state.token = tokens.token
            state.refreshToken = tokens.refreshToken
            
            var base64Payload = tokens.token.split('.')[1];
            var payload = Buffer.from(base64Payload, 'base64');
            var data = JSON.parse(payload.toString());
            state.expireIn = data.exp
            state.user = {
                id: data.id,
                login: data.login,
                email: data.email,
                role: data.role_id
            }
            localStorage.refreshToken = tokens.refreshToken
            localStorage.token = tokens.token
        },
        // Rename logout
        deleteUser (state) {
            state.user = null
            state.token = null
            state.refreshToken = null
            state.expireIn = null
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('brand')
            localStorage.removeItem('company')
        },
        storeCurrBrand (state, brand) {
            state.brand = brand.id
            if (brand)
                localStorage.brand = brand.id
        },
        removeCurrBrand (state) {
            state.brand = null
        },
        removeCurrCompany(state) {
            state.company = null
        },
        storeCurrCompany (state, company) {
            state.company = company.id
            if (company)
                localStorage.company = company.id
        },
        storeToken (state, token) {
            state.token = token
            localStorage.removeItem('token')
            localStorage.token = token
        }
    },
    actions: {
        storeToken({ commit}, token) {
            commit('storeToken', token)
        },
        storeCurrBrand({commit}, brand) {
            commit('storeCurrBrand', brand)
        },
        storeCurrCompany({commit}, company) {
            commit('storeCurrCompany', company)
        },
        removeCurrCompany({commit}) {
            commit('removeCurrCompany')
        },
        removeCurrBrand({commit}) {
            commit('removeCurrBrand')
        },
        login ({commit}, params) {
            return new Promise((resolve, reject) => {
                return Vue.prototype.$api.post('/auth/login', params)
                .then(res => {
                    commit('storeUser', res.data)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // editUser({commit}, params) {
        //     return Vue.prototype.$api.put('/users/' + params.id + '/edit', params)
        //     .then(res => {
        //         commit('editUser', res.data)
        //     }).catch(error => {
        //         console.log(error.response.data);
        //         console.log(error.response.status);
        //         console.log(error.response.headers);     
        //     })
        // },
        logout ({commit}, id) {
            return Vue.prototype.$api.delete('/auth/' + id)
            .then(() => {
                commit('deleteUser')
            })
        }
    },
    getters: {
        getUser: state => {
            return state.user
        },
        getLogin: state => {
            if (state.user)
                return state.user.login
            return null
        },
        getId: state => {
            if (state.user)
                return state.user.id
            return null
        },
        getToken: state => {
            return state.token
        },
        getRefreshToken: state => {
            return state.refreshToken
        },
        getBrand: state => {
            return state.brand
        },
        getCompany: state => {
            return state.company
        },
        getRole: state => {
            if (state.user)
                return state.user.role
            return null
        }
    }
});

export default store