<template>
  <v-app id="inspire">
    <AppBar v-if="$route.name != 'Login'"/>
    <v-main class="pl-14">
      <v-container fluid>
        {{ $router.name }}
        <router-view></router-view>
      </v-container>
    </v-main>
    <Snackbar/>
    <Footer/>
  </v-app>
</template>

<script>
import { VContainer, VMain, VApp } from "vuetify/lib";

import Footer from "@/components/footers/Footer";
import Snackbar from "@/components/snackbars/Snackbar.vue";

import AppBar from "@/components/AppBar.vue";

export default {
  components: {
    VContainer,
    VMain,
    VApp,
    Footer,
    AppBar,
    Snackbar
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 12px !important;
}

body {
    font-size: 12px !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
