<template>
  <v-dialog v-model="dialog" min-height="200px" max-width="500px" persistent>
    <v-card>
      <v-toolbar
        style="
          background: linear-gradient(
            to right,
            rgb(20, 84, 156),
            rgb(4, 119, 172)
          );
        "
        color="primary"
        dark
      >
        <v-spacer></v-spacer>
        <v-card-title class="justify-center">
          Suppression d'utilisateur
        </v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-card-text v-if="user" class="mt-10 justify-center"
        >Êtes vous sur de supprimer
        <span class="font-weight-bold">{{ user.login }}</span> ?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" dark @click="close(false)"
          >Annuler</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed dark @click="deleteUser"
          >Confirmer</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VToolbar,
  VSpacer,
  VBtn,
  VCardActions,
  VCardText,
  VCard,
  VCardTitle,
  VDialog,
} from "vuetify/lib";

export default {
  name: "UserDelete",
  components: {
    VToolbar,
    VSpacer,
    VBtn,
    VCardActions,
    VCardText,
    VCard,
    VCardTitle,
    VDialog,
  },
  props: {
    dialog: Boolean,
    user: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    deleteUser() {
      this.$api
        .delete("/users/" + this.user.id)
        .then(() => {
          this.close(true);
        })
        .catch(() => {
          this.close(false);
        })
        .finally(() => {});
    },
    close(val) {
      this.$emit("closeUserDelete", val);
    },
  },
};
</script>

<style>
</style>