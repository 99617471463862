<template>
<!--  V-CARD HERE GLOBAL -->
	<v-container fluid>
		<NavBarLeft />
		
		<v-row align="center">
			<DashboardTitle text="Marques" icon="fas fa-align-left"/>

			<v-spacer></v-spacer>

			<v-card flat style="border-radius: 10px !important">

			<ButtonPlus
			:condition="isAdmin || isAvocat"
			v-on:clicked="createBrandToggle = true"/>

			<ButtonDelete
			:condition="isAdmin"
			v-on:clicked="delBrand = true"/>

			<v-dialog v-model="delBrand" width="500" v-if="brands">
				<v-card>
					<v-toolbar color="primary" dark>
						<v-spacer></v-spacer>

						<v-card-title class="justify-center">
							Suppression de marque
						</v-card-title>

						<v-spacer></v-spacer>
					</v-toolbar>

					<v-divider></v-divider>

					<v-card-actions>
						<v-autocomplete
						label="Choisir une marque"
						v-if="brands"
						v-model="delName"
						:items="brandSelect"/>
					</v-card-actions>

					<v-card-actions class="justify-center">

						<ButtonValidate
						v-on:clicked="deleteBrand"/>

					</v-card-actions>
				</v-card>
			</v-dialog>
			</v-card>

    </v-row>

    <v-row v-if="brands && brands.length">
      <v-col v-for="brand in brands" :key="brand.id" cols="4" class="pa-2">
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 3 : 1"
            class="row-pointer mx-auto"
            :class="{ 'on-hover': hover }"
          >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2" class="align-right">
                <v-avatar rounded color="primary" class="white--text mr-1">
                  <span v-if="brand.img">
                    <img :src="convertImg(brand)"
                    style="max-width: 100%; max-height: 100%;"/>
                  </span>
                  <v-icon v-else class="white--text">mdi-image-filter-hdr</v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="9">
                <v-card-title class="primary--text font-weight-bold text-md-subtitle-1 pa-0">
                  {{ brand.name}}
                </v-card-title>
                <span class="text-caption black--text">
                  Dernière modification {{ formatDate(brand.date_update) }}
                </span>
                <v-divider class="mt-5">

                </v-divider>
              </v-col>
              <v-col cols="12" class="pt-4">
                <v-card-title  style="font-size: 12px; line-height: 15px" class="pa-0 pl-2">
                  Commentaire
                </v-card-title>
                <v-card-text  style="font-size: 12px;"  class="pa-0 pl-3">
                  {{ brand.comment ? brand.comment : "Rien à afficher" }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>

            <v-spacer></v-spacer>

            <v-card-text class="px-5 pb-5">
              <v-row class="grey lighten-5">
                <v-col cols="12" class="pb-0">
                  <v-avatar size="30" color="#C9F2E1">
                    <v-icon small dark color="#17B07B">
                      fas fa-clipboard-list
                    </v-icon>
                  </v-avatar>
                  <span class="font-weight-bold pl-2">
                    {{ brand.not_archived }} {{ brand.not_archived > 1 ? 'domaines' : 'domaine' }}
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-avatar size="30" color="#FFCDDD">
                    <v-icon small dark color="#FF5992"> fa-archive </v-icon>
                  </v-avatar>
                  <span class="font-weight-bold pl-2">
                    {{ brand.n_archived }} {{ brand.n_archived > 1 ? 'domaines archivés' : 'domaine archivé' }}</span>
                </v-col>
              </v-row>

              <v-row class="mt-6 pl-2" v-if="isAdmin || isAvocat">
                <v-tooltip bottom max-width="255px" color="grey darken-4">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" v-if="isAdmin">
                      <v-switch
                      color="#17B07B" hide-details small
                      label="Validation administrateur"
                      @click="setManualValidation(brand)"
                      v-model="brand.manual_validation"/>
                    </span>

                    <div v-else v-on="on">
                      <v-icon v-if="brand.manual_validation" class="pb-1" color="#17B07B">
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <v-icon v-else class="pb-1" color="grey">
                        mdi-close-circle
                      </v-icon>
                      {{ brand.manual_validation ? 'Validation administrateur active' : 'Validation administrateur inactive' }}
                    </div>
                  </template>
                  <span>
                    Lorsque la validation administrateur est activée chaque création de fiche de domaine
                    devra toujours être validée par un administrateur pour être visible dans le tableau de Surveillance.
                  </span>
                </v-tooltip>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer/>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-badge
                    color="red" overlap bordered depressed
                    :value="getNotifications(brand)" :content="getNotifications(brand)">
                      <v-btn
                      fab elevation="3"
                      @click="chooseBrand(brand)"
                      v-on="on" x-small color="secondary" class="white--text">
                        <v-icon>
                          mdi-magnify
                        </v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <span>
                    Consulter les domaines de la marque <span style="font-weight: 600">{{ brand.name }}</span>.
                  </span>
                </v-tooltip>

                <v-speed-dial
                v-if="isAdmin || isAvocat"
                v-model="brand.fab"
                class="ml-1"
                :open-on-hover="true"
                transition="scale-transition">
                  <template v-slot:activator>
                    <v-btn
                    v-model="brand.fab"
                    elevation="3"
                    x-small color="tertiary" dark fab>
                      <v-icon v-if="brand.fab">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn fab dark x-small @click="brand.edit = true" color="green">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn fab dark x-small color="red" :disabled="!isAdmin" @click="brand.suppressionDialog = true">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-speed-dial>
            </v-card-actions>

            <BrandCreateUpdate
            :create-brand-toggle="brand.edit"
            :brand="brand"
            @success="editSuccess(brand)"
            @fail="editFail(brand)"
            @close="editBrand(brand, false)"/>

            <ConfirmSuppressionDialog
            :text="'Vous êtes sur le point de supprimer la marque: ' + brand.name"
            :dialogModel="brand.suppressionDialog"
            v-on:close="brand.suppressionDialog = false"
            v-on:confirm="deleteOneBrand(brand.id)"/>

          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
      <v-col cols="12" class="pt-10">
        <v-subheader style="place-content: center">Pas de marque à afficher.</v-subheader>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbarDelete" timeout="2000" color="success">
      Suppression réussie.
    </v-snackbar>
    <v-snackbar v-model="snackbarCreate" timeout="2000" color="success">
      Création réussie
    </v-snackbar>
    <v-snackbar v-model="snackbarCreateFail" timeout="2000" color="error">
      Cet marque existe déjà
    </v-snackbar>
    <v-snackbar v-model="snackbarDeleteFail" timeout="2000" color="error">
      Erreur lors de la suppression
    </v-snackbar>

    <BrandCreateUpdate
    :create-brand-toggle="createBrandToggle"
    @success="createSuccess"
    @fail="createFail"
    @close="createBrandToggle = false"/>

  </v-container>
</template>

<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import BrandCreateUpdate from "@/components/dialogs/BrandCreateUpdate.vue";

import DashboardTitle from "@/components/texts/DashboardTitle.vue";
import ButtonPlus from "@/components/buttons/ButtonPlus";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonValidate from "@/components/buttons/ButtonValidate";

import ConfirmSuppressionDialog from "@/components/dialogs/ConfirmSuppressionDialog"

import {
  VContainer,
  VRow,
  VCol,
  VSpacer,
  VHover,
  VCardActions,
  VAutocomplete,
  VDivider,
  VCardTitle,
  VToolbar,
  VAvatar,
  VIcon,
  VCardText,
  VCard,
  VDialog,
  VProgressCircular,
  VSnackbar,
} from "vuetify/lib";

export default {
  name: "Brands",
  components: {
    NavBarLeft,
    BrandCreateUpdate,
    VContainer,
    VRow,
    VCol,
    VSpacer,
    VHover,
    VCardActions,
    VAutocomplete,
    VDivider,
    VCardTitle,
    VToolbar,
    VAvatar,
    VIcon,
    VCardText,
    VCard,
    VDialog,
    VProgressCircular,
    VSnackbar,
    DashboardTitle,
    ButtonPlus,
    ButtonDelete,
    ButtonValidate,
    ConfirmSuppressionDialog
  },
  data: () => ({
    loading: true,
    brands: null,
    delBrand: false,
    name: null,
    delName: null,
    isAdmin: false,
    isAvocat: false,
    createBrandToggle: false,
    snackbarDelete: false,
    snackbarCreate: false,
    snackbarCreateFail: false,
    snackbarDeleteFail: false,
    snackbarEditFail: false,
    snackbarEdit: false,
  }),
  created() {
    this.$store.dispatch("removeCurrBrand");
    this.getBrands();
    if (this.$store.getters.getRole === "admin") this.isAdmin = true;
    if (this.$store.getters.getRole === "avocat") this.isAvocat = true;
  },
  methods: {
    getNotifications(brand) {
      if (this.$store.state.user.role == 'avocat' || this.$store.state.user.role == 'admin')
        return parseInt(brand.avocat_notif, 10);
      return parseInt(brand.user_notif, 10);
    },
    setManualValidation(brand) {
      this.$api.put("/brands/" + brand.id, {
        manual_validation: brand.manual_validation ? true : false,
      }).catch(() => {
        brand.manual_validation = false;
      })
    },
    convertImg(item) {
      return 'data:image/png;base64,' + item.img;
    },
    createSuccess() {
      this.snackbarCreate = true;
      this.createBrandToggle = false;
      this.getBrands();
    },
    createFail() {
      this.createBrandToggle = false;
      this.snackbarCreateFail = true;
    },
    editSuccess(brand) {
      this.snackbarEdit = true;
      brand.edit = false;
      this.getBrands();
    },
    editFail(brand) {
      brand.edit = false;
      this.snackbarEditFail = true;
    },
    editBrand(brand, value) {
      brand.edit = value;
    },
    formatDate: function (item) {
      let date = new Date(item);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month + "-" + dt;
    },
    getBrands() {
      this.loading = true;
      this.$api
        .get("/brands", {params: { company_id: this.$store.state.company }})
        .then((res) => {
          for (let i in res.data) {
            res.data[i]['edit'] = false;
            res.data[i]['fab'] = false;
            res.data[i]['suppressionDialog'] = false;
          }
          this.brands = res.data;
          this.brands.sort((a, b) => a.name.localeCompare(b.name))
        })
        .catch(() => {
          this.brands = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteOneBrand(id) {
      this.loading = true;
      this.$api
        .delete("/brands/" + id)
        .then(() => {
          this.snackbarDelete = true;
          this.getBrands();
        })
        .catch(() => {
          this.snackbarDeleteFail = true;
        })
        .finally(() => {
          this.loading = false;
          this.delBrand = false;
        });
    },
    deleteBrand() {
      this.loading = true;
      let id = this.brands.find((x) => x.name === this.delName).id;
      this.$api
        .delete("/brands/" + id)
        .then(() => {
          this.snackbarDelete = true;
          this.getBrands();
        })
        .catch(() => {
          this.snackbarDeleteFail = true;
        })
        .finally(() => {
          this.loading = false;
          this.delBrand = false;
        });
    },
    chooseBrand(item) {
      this.$store.dispatch("storeCurrBrand", item).then(() => {
        this.$router.push("/monitoring/" + item.id);
      });
    },
  },
  computed: {
    brandSelect() {
      return this.brands.map((brand) => brand.name);
    },
  },
};
</script>

<style>

	.dashboard-header-actions {
		border-radius: 50% !important;
	}

  .v-label {
    font-size: 14px !important;
    color: black;
    font-weight: 400;
  }

</style>
