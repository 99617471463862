import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const api = {
    state: {
        companies: {
            url: "/company",
            setMethod: "setCompanies",
            state: "companies",
            messages: {
                success: {
                    post: "Entreprise enregistrée avec succès.",
                    put: "Entreprise modifiée avec succès",
                    delete: "Entreprise supprimée avec succès",
                },
                error: {
                    post: "Erreur lors de l'enregistrement de l'entreprise.",
                    put: "Erreur lors de l'enregistrement de l'entreprise.",
                    delete: "Erreur lors de la suppresion de l'entreprise.",
                    get: "Erreur lors de la récupération des entreprises."
                }
            }
        },
        brands: {
            url: "/brands",
            setMethod: "setBrands",
            state: "brands",
            messages: {
                success: {
                    post: "Marque enregistrée avec succès.",
                    put: "Marque modifiée avec succès",
                    delete: "Marque supprimée avec succès",
                },
                error: {
                    post: "Erreur lors de l'enregistrement de la marque.",
                    put: "Erreur lors de l'enregistrement de la marque.",
                    delete: "Erreur lors de la suppresion de la marque.",
                    get: "Erreur lors de la récupération des marques."
                }
            }
        },
        domains: {
            url: "/domains",
            setMethod: "",
            state: "domains",
            messages: {
                success: {
                    post: "Domaine enregistré avec succès.",
                    put: "Domaine modifié avec succès",
                    delete: "Domaine supprimé avec succès",
                },
                error: {
                    post: "Erreur lors de l'enregistrement de la domaine.",
                    put: "Erreur lors de l'enregistrement de la domaine.",
                    delete: "Erreur lors de la suppresion de la domaine.",
                    get: "Erreur lors de la récupération des domaine."
                }
            }
        },
    },
    actions: {
        setSnackbarSuccess({rootState}, message) {
            rootState.snackbar.status = "success";
            rootState.snackbar.msg = message;
            rootState.snackbar.model = true;
        },
        setSnackbarError({rootState}, message) {
            rootState.snackbar.status = "error";
            rootState.snackbar.msg = message;
            rootState.snackbar.model = true;
        },
        setBrands({state, rootState}, brands) {
            state;
            for (let i in brands) {
                brands[i]['edit'] = false;
                brands[i]['fab'] = false;
                brands[i]['suppressionDialog'] = false;
              }
              rootState.brands = brands;
              rootState.brands.sort((a, b) => a.name.localeCompare(b.name));

              return rootState.brands;
        },
        setCompanies({state, rootState}, companies) {
            state;
            for (let i in companies) {
                companies[i]['edit'] = false;
                companies[i]['fab'] = false;
                companies[i]['suppressionDialog'] = false;
            }
            rootState.companies = companies;
            rootState.companies.sort((a, b) => a.name.localeCompare(b.name));

            return rootState.companies;
        },
        get({rootState, dispatch}, params) {
            return new Promise((resolve, reject) => {
                rootState.loading = true;
                let url = params.id ? params.route.url + "/" + params.id : params.route.url;
                if (params.url)
                    url = params.url
                Vue.prototype.$api.get(url, params.params).then((response) => {
                    if (params.route.setMethod.length != 0)
                        dispatch(params.route.setMethod, response.data).then((final_data) => {
                            resolve(final_data);
                        });
                }).catch((error) => {
                    rootState[params.route.state] = [];
                    dispatch('setSnackbarError', params.route.messages.error.get);
                    reject(error)
                }).finally(() => {
                    rootState.loading = false;
                });
            });
        },
        delete({dispatch}, params) {
            return new Promise((resolve, reject) => {
                let url = params.route.url;
                if (params.id)
                    url = params.route.url +  "/" + params.id;
                if (params.url)
                    url = params.url

                Vue.prototype.$api.delete(url).then(() => {
                    dispatch('setSnackbarSuccess', params.route.messages.success.delete);
                    resolve()
                }).catch(() => {
                    dispatch('setSnackbarError', params.route.messages.error.delete);
                    reject()
                })
            });
        },
        post({dispatch}, params) {
            return new Promise((resolve, reject) => {
                let url = params.route.url;
                if (params.url)
                    url = params.url

                Vue.prototype.$api.post(url, params.data).then(() => {
                    dispatch('setSnackbarSuccess', params.route.messages.success.post);
                    resolve()
                }).catch((error) => {
                    console.log("ERROR ", error);
                    dispatch('setSnackbarError', params.route.messages.error.post);
                    reject()
                })
            });
        },
        put({dispatch}, params) {
            return new Promise((resolve, reject) => {
                let url = params.id ? params.route.url + "/" + params.id : params.route.url;
                if (params.url)
                    url = params.url

                Vue.prototype.$api.put(url, params.data).then(() => {
                    dispatch('setSnackbarSuccess', params.route.messages.success.put);
                    resolve()
                }).catch(() => {
                    dispatch('setSnackbarError', params.route.messages.error.put);
                    reject()
                })
            });
        }
    },
}

export default api