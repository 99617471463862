<template>
  <v-container fluid>
    <!-- <h1 class="text-h3">Scan Avocats</h1> -->
    <v-img
      class="mx-auto mt-4"
      src="@/assets/Logo_crop.jpg"
      max-height="150"
      max-width="250"
    ></v-img>
    <!-- <img src="@/assets/Logo-SCAN_page-0001.jpg" alt=""> -->
    <v-container fill-height fluid style="height: 60vh">
      <v-row align="center" justify="center" v-if="notValid">
        <v-col cols="4"></v-col>
        <v-col cols="4"
          ><span class="error--text"
            >Login ou mot de passe incorrect</span
          ></v-col
        >
        <v-col cols="4"></v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <v-form ref="form" @submit.prevent="submit">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.username"
                    :rules="rules.username"
                    color="black"
                    label="Login"
                    required
                    outlined
                    v-on:keyup.enter="Auth"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.password"
                    :rules="rules.password"
                    color="black"
                    label="Mot de passe"
                    required
                    outlined
                    type="password"
                    v-on:keyup.enter="Auth"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col cols="4"></v-col>
        <v-card-actions elevation="2" outlined>
          <v-spacer></v-spacer>
          <v-btn dark color="#075f91" @click="Auth"> Se connecter </v-btn>
        </v-card-actions>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import {
  VImg,
  VContainer,
  VRow,
  VCol,
  VBtn,
  VSpacer,
  VTextField,
  VForm,
  VCardActions,
} from "vuetify/lib";

export default {
  components: {
    VImg,
    VContainer,
    VRow,
    VCol,
    VBtn,
    VSpacer,
    VTextField,
    VForm,
    VCardActions,
  },
  data() {
    const defaultForm = Object.freeze({
      username: "",
      password: "",
    });
    return {
      form: Object.assign({}, defaultForm),
      rules: {
        username: [(val) => (val || "").length > 0 || "Ce champ est requis"],
        password: [(val) => (val || "").length > 0 || "Ce champ est requis"],
      },
      // snackbar: false,
      defaultForm,
      notValid: false,
    };
  },
  methods: {
    resetForm() {
      this.form = Object.assign({}, this.defaultForm);
      this.$refs.form.reset();
    },
    submit() {
      this.resetForm();
    },
    Auth() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("login", {
            login: this.form.username,
            password: this.form.password,
          })
          .then(() => {
            this.$router.push("/companies");
          })
          .catch(() => {
            this.notValid = true;
            setTimeout(() => {
              this.notValid = false;
            }, 3000);
          });
      }
    },
  },
};
</script>

<style scoped>
div[aria-required="true"].v-input .v-label::after {
  content: " *";
  color: red;
}
</style>