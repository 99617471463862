<template>
<!--  V-CARD HERE GLOBAL -->
	<v-container fluid>
		<NavBarLeft />
		<v-row>
			<DashboardTitle text="Entreprises" icon="as fa-building"/>
      <v-spacer/>
      <v-card flat style="border-radius: 10px !important">
        <ButtonPlus
        :condition="isAdmin"
        v-on:clicked="createCompanyToggle = true"/>

        <ButtonDelete
        :condition="isAdmin"
        v-on:clicked="delCompany = true"/>

        <v-dialog v-model="delCompany" width="500" v-if="companies">
          <v-card>
            <v-toolbar color="primary" dark>
              <v-spacer></v-spacer>

              <v-card-title class="justify-center">
                Suppression de marque
              </v-card-title>

              <v-spacer></v-spacer>
            </v-toolbar>

            <v-divider/>

            <v-card-actions>
              <v-autocomplete
              label="Choisir une marque"
              v-if="companies"
              v-model="delName"
              :items="companieselect"/>
            </v-card-actions>

            <v-card-actions class="justify-center">

              <ButtonValidate
              v-on:clicked="deleteCompany"/>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-row>

    <v-row v-if="companies">
      <v-col v-for="company in companies" :key="company.id" cols="4" class="pa-2">
        <v-hover v-slot="{ hover }">
          <v-card
          :elevation="hover ? 3 : 1"
          class="row-pointer mx-auto"
          :class="{ 'on-hover': hover }">

          <v-card-text>

            <v-row no-gutters>
              <v-col cols="2">
                <v-avatar rounded color="primary" class="white--text mr-1">
                  <span v-if="company.img">
                    <img :src="'data:image/png;base64,' + company.img"
                    style="max-width: 100%; max-height: 100%;"/>
                  </span>
                  <v-icon v-else class="white--text">mdi-image-filter-hdr</v-icon>
                </v-avatar>
              </v-col>

              <v-col cols="9">
                <v-card-title class="primary--text font-weight-bold text-md-subtitle-1 pa-0">
                  {{ company.name }}
                </v-card-title>
                <span class="text-caption black--text">
                  Dernière modification {{ formatDate(company.updated_at) }}
                </span>

                <v-divider class="mt-5"/>
              </v-col>

              <v-col cols="12" class="pt-4">
                <v-card-title  style="font-size: 12px; line-height: 15px" class="pa-0 pl-2">
                  Commentaire
                </v-card-title>
                <v-card-text  style="font-size: 12px;"  class="pa-0 pl-3">
                  {{ company.comment ? company.comment : "Rien à afficher" }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer/>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-badge
                  color="red" overlap bordered depressed
                  :value="getNotifications(company)" :content="getNotifications(company)">
                    <v-btn
                    fab elevation="3"
                    @click="chooseCompany(company)"
                    v-on="on" x-small color="secondary" class="white--text">
                      <v-icon>
                        mdi-magnify
                      </v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span>
                  Consulter les marques de l'entreprise <span style="font-weight: 600">{{ company.name }}</span>.
                </span>
              </v-tooltip>

              <v-speed-dial
              v-if="isAdmin"
              v-model="company.fab"
              class="ml-1"
              :open-on-hover="true"
              transition="scale-transition">
                <template v-slot:activator>
                  <v-btn
                  v-model="company.fab"
                  elevation="3"
                  x-small color="tertiary" dark fab>
                    <v-icon v-if="company.fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn fab dark x-small @click="company.edit = true" color="green">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn fab dark x-small color="red" :disabled="!isAdmin" @click="company.suppressionDialog = true">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>
          </v-card-actions>

          <CompanyCreateUpdate
          :create-company-toggle="company.edit"
          :company="company"
          @success="edit(company, 'success')"
          @fail="edit(company,'fail')"
          @close="company.edit = false"/>

          <ConfirmSuppressionDialog
          :text="'Vous êtes sur le point de supprimer l\'entreprise: ' + company.name"
          :dialogModel="company.suppressionDialog"
          v-on:close="company.suppressionDialog = false"
          v-on:confirm="deleteOneCompany(company.id)"/>

          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>

    <CompanyCreateUpdate
    :create-company-toggle="createCompanyToggle"
    @success="create"
    @fail="this.createCompanyToggle = false;"
    @close="createCompanyToggle = false"/>

  </v-container>
</template>

<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import CompanyCreateUpdate from "@/components/dialogs/CompanyCreateUpdate.vue";

import DashboardTitle from "@/components/texts/DashboardTitle.vue";
import ButtonPlus from "@/components/buttons/ButtonPlus";
import ButtonValidate from "@/components/buttons/ButtonValidate";
import ButtonDelete from "@/components/buttons/ButtonDelete";

import ConfirmSuppressionDialog from "@/components/dialogs/ConfirmSuppressionDialog";

import MapStore from "@/mixins/MapStore";

import {
  VContainer,
  VRow,
  VCol,
  VSpacer,
  VHover,
  VCardActions,
  VAutocomplete,
  VDivider,
  VCardTitle,
  VToolbar,
  VAvatar,
  VIcon,
  VCardText,
  VCard,
  VDialog,
  VProgressCircular,
} from "vuetify/lib";

export default {
  name: "Companies",
  components: {
    NavBarLeft,
    VContainer,
    VRow,
    VCol,
    VSpacer,
    VHover,
    VCardActions,
    VAutocomplete,
    VDivider,
    VCardTitle,
    VToolbar,
    VAvatar,
    VIcon,
    VCardText,
    VCard,
    VDialog,
    VProgressCircular,
    DashboardTitle,
    ButtonPlus,
    ButtonDelete,
    ButtonValidate,
    CompanyCreateUpdate,
    ConfirmSuppressionDialog
  },
  mixins: [ MapStore ],
  data: () => ({
    companies: null,
    delCompany: false,
    name: null,
    delName: null,
    createCompanyToggle: false,
  }),
  created() {
    this.$store.dispatch("removeCurrCompany");
    this.getCompanies();
  },
  computed: {
    companieselect() {
      return this.companies.map((company) => company.name);
    },
  },
  methods: {
    getNotifications(company) {
      if (this.$store.state.user.role == 'avocat' || this.$store.state.user.role == 'admin')
        return parseInt(company.avocat_notif, 10);
      return parseInt(company.user_notif, 10);
    },
    getCompanies() {
      this.$store.dispatch('get', { route: this.api.companies })
      .then((data) => {
        this.companies = data;
      })
    },
    create() {
      this.createCompanyToggle = false;
      this.getCompanies();
    },
    edit(company, status) {
      company.edit = false;
      if (status == "success") {
        this.getCompanies();
      }
    },
    deleteOneCompany(id) {
      this.$store.dispatch('delete',{ route: this.api.companies, id: id })
      .then(() => {
        this.$store.dispatch('get', { route: this.api.companies })
        .then((data) => {
          this.companies = data;
        })
      }).finally(() => {
        this.delCompany = false;
      })
    },
    deleteCompany() {
      let id = this.companies.find((x) => x.name === this.delName).id;
      this.deleteOneCompany(id);
    },
    chooseCompany(item) {
      this.$store.dispatch("storeCurrCompany", item).then(() => {
        this.$router.push("/brands?company_id=" + item.id);
      });
    },
  },
};
</script>

<style>

	.dashboard-header-actions {
		border-radius: 50% !important;
	}

</style>