<template>
  <v-container fluid>
    <NavBarLeft />
    <v-data-table
      :headers="headers"
      :items="users"
      sort-by="login"
      class="elevation-1"
      :footer-props="pageProps"
      disable-sort
    >
      <template v-slot:top>
        <v-toolbar flat>
          <DashboardTitle
          text="UTILISATEURS"/>

          <v-spacer></v-spacer>

          <ButtonPlus
          :condition="true"
          v-on:clicked="dialog = true"/>

          <UserDelete
            v-if="dialogDelete"
            @closeUserDelete="deleteToggle"
            :user="deletedUser"
            :dialog="dialogDelete"
          />
          <UserCreate
            v-if="dialog"
            @closeUserCreate="createToggle"
            @loginError="snacbkarCreateError = true"
            :companies="companies"
            :dialog="dialog"
          />
          <UserEdit
            v-if="dialogEdit"
            @closeUserEdit="editToggle"
            @editError="snacbkarEditUserError = true"
            :user="editedUser"
            :companies="companies"
            :dialog="dialogEdit"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn color="grey" dark small icon>
          <v-icon small @click="editItem(item)"> fas fa-edit </v-icon>
        </v-btn>
      </template>

      <template v-if="getAdmin" v-slot:item.delete="{ item }">
        <v-icon small @click="deleteItem(item)"> fas fa-trash-alt </v-icon>
      </template>

      <template v-slot:item.role_id="{ item }">
        <v-chip :color="item.role_id == 'client' ? 'green' : 'orange'" class="white--text" style="font-weight: 400">
          {{ item.role_id }}
        </v-chip>
      </template>

      <template v-slot:no-data> Aucun utilisateurs </template>
    </v-data-table>

    <v-snackbar v-model="snackbarNewUser" timeout="2000" color="success">
      Création réussie
    </v-snackbar>
    <v-snackbar v-model="snackbarDeleteUser" timeout="2000" color="success">
      Suppression réussie
    </v-snackbar>
    <v-snackbar v-model="snacbkarEditUser" timeout="2000" color="success">
      Édition réussie
    </v-snackbar>
    <v-snackbar v-model="snacbkarCreateError" timeout="2000" color="success">
      Cet utilisateur existe déjà.
    </v-snackbar>
  </v-container>
</template>

<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import UserCreate from "@/components/UserCreate.vue";
import UserDelete from "@/components/UserDelete.vue";
import UserEdit from "@/components/UserEdit.vue";

import ButtonPlus from "@/components/buttons/ButtonPlus";
import DashboardTitle from "@/components/texts/DashboardTitle";

import {
  VToolbar,
  VSpacer,
  VBtn,
  VDataTable,
  VIcon,
  VContainer,
  VSnackbar,
} from "vuetify/lib";

export default {
  name: "Users",
  components: {
    NavBarLeft,
    UserCreate,
    UserDelete,
    UserEdit,
    VToolbar,
    VSpacer,
    VBtn,
    VDataTable,
    VIcon,
    VContainer,
    VSnackbar,
    ButtonPlus,
    DashboardTitle
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      users: [],
      isAdmin: false,
      headers: [
        {
          text: "Login",
          align: "start",
          value: "login",
        },
        { text: "Email", value: "email" },
        { text: "Entreprises", value: "companies" },
        { text: "Role", value: "role_id" },
        { text: "", value: "edit" },
        { text: "", value: "delete" },
      ],
      snackbarNewUser: false,
      snackbarDeleteUser: false,
      snacbkarEditUser: false,
      snacbkarEditUserError: false,
      snacbkarCreateError: false,
      editedUser: null,
      deletedUser: null,
      companies: [],
    };
  },
  computed: {
    getAdmin() {
      return this.isAdmin;
    },
    pageProps() {
      return { itemsPerPageOptions: [25, 50, 100] };
    },
  },
  created() {
    if (this.$store.getters.getUser.role === "admin") this.isAdmin = true;
    this.getUsers();
    this.getCompanies();
  },
  methods: {
    createToggle(event) {
      this.dialog = false;
      this.snackbarNewUser = event;
      if (event === true) {
        this.getCompanies();
        this.getUsers();
      }
    },
    deleteToggle(event) {
      this.dialogDelete = false;
      this.snackbarDeleteUser = event;
      if (event === true) this.getUsers();
    },
    editToggle(event) {
      this.dialogEdit = false;
      this.snacbkarEditUser = event;
      if (event === true) this.getUsers();
    },
    getCompanies() {
      this.$api
        .get("/company")
        .then((res) => {
          this.companies = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsers() {
      this.$api.get("/users").then((res) => {
        this.users = res.data;
      });
    },
    deleteItem(item) {
      this.deletedUser = Object.assign({}, item);
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedUser = Object.assign({}, item);
      this.dialogEdit = true;
    },
  },
};
</script>