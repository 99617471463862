<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container fluid>
    <NavBarLeft />
    <v-data-table
      v-if="domains"
      class="row-pointer"
      :headers="domainsHeader"
      :items="domainsData"
      item-key="id"
      :expanded.sync="expanded"
      :loading="loading"
      :footer-props="pageProps"
      :search="search"
      :custom-sort="customSort"
      @click:row="handleClickRow">
	
<!-- TITLE -->
		<template v-slot:top>
			<v-toolbar flat>
				<DashboardTitle class="mt-5"
				:text="'SURVEILLANCE ' + brand.name"/>
				
				<v-spacer></v-spacer>
				<ButtonPlus
				:condition="isAdmin || isAvocat"
				v-on:clicked="createDomainToggle = true"/>
			</v-toolbar>
      <v-row>
        <v-col cols="4">
          <p style="width: 75%" class="ml-4">
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Recherche"
              hide-details
            />
          </p>
          <p class="ml-4 font-italic text-left caption">
            Le tableau est trié par défaut par date de signalement.
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2 mb-4">
          <div class="d-flex flex-column">
            <div class="pa-2 pl-4 pr-4 orange lighten-1 chip-custom">Dossier en cours</div>
            <div class="pa-2 pl-4 pr-4 deep-purple lighten-1 chip-custom">En attente d'instruction client</div>
          </div>
        </v-col>
        <v-col cols="2">
          <div class="d-flex flex-column">
            <div class="pa-2 pl-4 pr-4 yellow lighten-1 chip-custom">Monitoring seule</div>
            <div class="pa-2 pl-4 pr-4 blue lighten-1 chip-custom">DEC - En attente d'instruction client</div>
          </div>
        </v-col>
        <v-col cols="2 mb-4">
          <div class="d-flex flex-column">
            <div class="pa-2 pl-4 pr-4 green lighten-1 chip-custom">NDD Client</div>
            <div class="pa-2 pl-4 pr-4 blue-grey lighten-1 chip-custom">Signalement pour info</div>
          </div>
        </v-col>
      </v-row>
		</template>

<!-- HEADER -->
		<template v-for="header in domainsHeader" v-slot:[`header.${header.value}`]="{ header }">
      <span :key="header.name">{{ header.text }}</span> 
			<v-menu
        v-if="header.filterable"
        :key="header.value"
        offset-y
        :close-on-content-click="false"
      >
				<template v-slot:activator="{ on, attrs }">						
						<v-btn small icon v-bind="attrs" v-on="on">
              <v-icon small :color="filters[header.value] ? 'primary' : ''">
                fas fa-filter
              </v-icon>
						</v-btn>
				</template>
				
				<div style="background-color: white; width: 280px">
					<v-text-field
					v-model="filters[header.value]"
					clearable class="pa-4" type="text"
					:label="header.filterLabel"/>
				</div>
			</v-menu>
		</template>

<!-- VALUES -->
		<template v-slot:item.domain="{ item }">
			<span v-if="holderSize(item.domain)">
				{{ holderLimit(item.domain) }}
			</span>
			<span v-else>{{ item.domain ? item.domain : "-" }}</span>
		</template>
		
		<template v-slot:item.color="{ item }">
			<v-tooltip left>
				<template v-slot:activator="{ on, attrs }">
					<v-chip
					:color="getColor(item.color)" dark small
					v-bind="attrs" v-on="on"/>
				</template>
				
				<span>{{ item.color ? item.color : "Autres" }}</span>
			</v-tooltip>
		</template>
		
		<template v-slot:item.holder="{ item }">
			<span v-if="holderSize(item.holder)">
			{{ holderLimit(item.holder) }}
			</span>
			<span v-else>
			{{ item.holder }}
			</span>
		</template>

		<template v-slot:item.follow="{ item }">
			<span v-if="holderSize(item.follow)">
				{{ holderLimit(item.follow) }}
			</span>
			<span v-else>{{ item.follow ? item.follow : "-" }}</span>
		</template>

		<template v-slot:item.date_report="{ item }">
			<span>{{ formatDate(item.date_report) }}</span>
		</template>

		<template v-slot:item.instruction="{ item }">
			<span v-if="holderSize(item.instruction)">
				{{ holderLimit(item.instruction) }}
			</span>
			<span v-else>{{ item.instruction ? item.instruction : "-" }}</span>
		</template>

		<template v-slot:item.reco_scan="{ item }">
			<span v-if="holderSize(item.reco_scan)">
				{{ holderLimit(item.reco_scan) }}
			</span>
			<span v-else>{{ item.reco_scan ? item.reco_scan : "-" }}</span>
		</template>

		<template v-slot:item.usage="{ item }">
			<span v-if="holderSize(item.usage)">
				{{ holderLimit(item.usage) }}
			</span>
			<span v-else>{{ item.usage ? item.usage : "-" }}</span>
		</template>

		<template v-slot:item.edit="{ item }">
      <v-badge
        :value="!item.visible || (domainHasChanged(item))"
        color="red"
        dot
        offset-x="10"
        offset-y="10">
        <HandleDomainMenu
        :isAvocat="isAvocat"
        :isAdmin="isAdmin"
        :visible="item.visible"
        :changed="domainHasChanged(item)"
        v-on:edit="editDomain(item)"
        v-on:delete="deleteDomain(item)"
        v-on:archive="archiveDomain(item)"
        v-on:validate="validateDomain(item)"/>
      </v-badge>
		</template>

<!-- EXPANSIONS -->
		<template v-slot:expanded-item="{ item }">
			<td :colspan="item.length" class="grey lighten-5 pa-0" style="vertical-align: top; border-bottom: 1px solid" v-for="(col, key) in domainsHeader" :key="key">
				<v-sheet flat class="transparent px-4"  :max-length="item.length" v-if="item[col.value] && ['date_report', 'data-table-expand', 'edit', 'color', 'holder'].includes(col.value) == false">
					<v-card-title style="font-size: 12px" class="pa-0 pt-0 pb-0 pl-0"> {{ col.text }} </v-card-title>
					<v-card-text style="font-size: 12px" class="pa-0 pt-0"> {{ item[col.value] }} </v-card-text>
				</v-sheet>
			</td>
		</template>
    <template slot="no-data"> Pas de données disponible. </template>
    </v-data-table>

    <v-fab-transition>
      <v-btn fixed right bottom fab large
      style="margin-bottom: 60px; margin-right: 20px;"
      v-show="windowTop"
      v-scroll="onScroll"
      @click="$vuetify.goTo(0, options)" color="primary">
        <v-icon>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="dialogDelete" max-width="500px">
		<v-card class="py-4">
			<v-card-title>
				<v-spacer/>
				<DashboardTitle
				v-if="deletedItem"
				:text="'Supprimer le domaine ' + deletedItem.domain + ' ?'"/>
				<v-spacer/>
			</v-card-title>
			<v-card-actions>
				<v-spacer/>
				<ButtonCancel :condition="false" v-on:clicked="closeDeleteConfirm"/>
				<ButtonValidate :condition="false" v-on:clicked="deleteDomainConfirm"/>
				<v-spacer/>
			</v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogArchive" max-width="500px">
		<v-card class="py-4">
			<v-card-title>
				<v-spacer/>
				<DashboardTitle
				v-if="archivedItem"
				:text="'Archiver le domaine ' + archivedItem.domain + ' ?'"/>
				<v-spacer/>
			</v-card-title>
			<v-card-actions>
				<v-spacer/>
				<ButtonCancel :condition="false" v-on:clicked="closeArchiveConfirm"/>
				<ButtonValidate :condition="false" v-on:clicked="archiveDomainConfirm"/>
				<v-spacer/>
			</v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogValidate" max-width="500px">
		<v-card class="py-4">
			<v-card-title>
				<v-spacer/>
				<DashboardTitle
				v-if="validateItem"
				:text="'Valider le domaine ' + validateItem.domain + ' ?'"/>
				<v-spacer/>
			</v-card-title>
			<v-card-actions>
				<v-spacer/>
				<ButtonCancel :condition="false" v-on:clicked="closeValidateConfirm"/>
				<ButtonValidate :condition="false" v-on:clicked="validateDomainConfirm"/>
				<v-spacer/>
			</v-card-actions>
      </v-card>
    </v-dialog>

    <CreateDomain
      @closeDomainCreate="closeDomainCreate"
      @createdDomainSuccess="closeAndUpdateCreate"
      @createError="snackbarCreateError = true"
      :dialog="createDomainToggle"
      :brand="this.$route.params.id"
    />

    <EditDomain
      v-if="editedDomain"
      @closeDomain="closeDomain"
      @editDomain="closeAndUpdateEdit"
      :dialog="editDomainToggle"
      :brand="this.$route.params.id"
      :domain="editedDomain"
    />
  </v-container>
</template>

<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import CreateDomain from "../components/CreateDomain.vue";

import EditDomain from "../components/EditDomain.vue";

import MonitoringHeader from "../helpers/MonitoringHeader";
import MonitoringUserHeader from "../helpers/MonitoringUserHeader";
import FilterMonitoring from "../mixins/FilterMonitoring";

import DashboardTitle from "@/components/texts/DashboardTitle";
import ButtonPlus from "@/components/buttons/ButtonPlus";
import ButtonCancel from "@/components/buttons/ButtonCancel";
import ButtonValidate from "@/components/buttons/ButtonValidate";

import HandleDomainMenu from "@/components/menus/HandleDomainMenu";

import MapStore from "@/mixins/MapStore";

import {
  VDialog,
  VCard,
  VToolbar,
  VSpacer,
  VCardActions,
  VTextField,
  VBtn,
  VCardTitle,
  VDataTable,
  VIcon,
  VTooltip,
  VMenu,
  VChip,
  VContainer,
  VCardText,
} from "vuetify/lib";

export default {
  name: "Monitoring",
  components: {
    NavBarLeft,
    CreateDomain,
    EditDomain,
    // EditionDomainNewMenuVue,
    VDialog,
    VCard,
    VToolbar,
    VSpacer,
    VCardActions,
    VTextField,
    VBtn,
    VCardTitle,
    VDataTable,
    VIcon,
    VTooltip,
    VMenu,
    VChip,
    VContainer,
    VCardText,
    DashboardTitle,
    ButtonPlus,
	HandleDomainMenu,
	ButtonCancel,
	ButtonValidate
  },
  mixins: [ MapStore, FilterMonitoring ],
  data() {
    return {
		expanded: [],
		createDomainToggle: false,
		editDomainToggle: false,
		editDomainToggleUser: false,
		snackBarNewDomain: false,
		snackBarDeleteDomain: false,
    snackBarDeleteDomainError: false,
		snackbarArchiveDomain: false,
    snackbarValidateDomain: false,
		snackBarEditDomain: false,
		snacbkarEditAsUser: false,
		snackbarCreateError: false,
		brand: null,
		domains: null,
		editedDomain: null,
		editedItemUser: null,
		deletedItem: null,
		archivedItem: null,
		dialogDelete: false,
		dialogArchive: false,
    dialogValidate: false,
    validateItem: null,
		windowTop: false,
    };
  },
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch('get', {
      route: this.api.brands,
      id: id,
      params: { company_id: this.$store.state.company }
    }).then((data) => {
      this.brand = data[0];
    }).catch((error) => {
      if (error.response.status === 403) this.$router.push("/brands");
    })
    this.getDomains();
  },
  computed: {
    options () {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      }
    },
    domainsData() {
      let conditions = [];
      let funcs = this.getFiltersFunc();
      for (const property in this.filters) {
        if (this.filters[property]) conditions.push(funcs[property]);
      }
      if (conditions.length > 0) {
        return this.domains.filter((domain) => {
          return conditions.every((condition) => {
            return condition(domain);
          });
        });
      }
      return this.domains;
    },
    pageProps() {
      return { itemsPerPageOptions: [100, 50, 15] };
    },
    domainsHeader() {
      let val = this.isAdmin ? true : false;
      if (val) {
        return MonitoringHeader();
      } else {
        return MonitoringUserHeader();
      }
    },
	},
	methods: {
    domainHasChanged(domain){
      if (
        (domain.to_user && this.$store.state.user.role == "client") ||
        (domain.to_avocat && (this.$store.state.user.role == "avocat" ||  this.$store.state.user.role == "admin"))
      )
        return true;
      return false;
    },
		handleClickRow(event) {
			let index = this.expanded.indexOf(event, 0);
	
			if (index != -1) {
				this.expanded.splice(index, 1);
			} else {
				this.expanded.push(event);
			}
		},
	onScroll () {
		const top = (
        window.pageYOffset ||
        document.documentElement.offsetTop ||
        0
      )
      this.windowTop = top > 300
    },
    closeDomainAsUser() {
      this.editDomainToggleUser = false;
      this.editedItemUser = null;
    },
    closeDomainCreate() {
      this.createDomainToggle = false;
    },
    getDeleteNotif() {
      if (this.$store.state.user.role == "avocat" || this.$store.state.user.role == "admin")
        return {
          to_avocat: true,
        }
      if (this.$store.state.user.role == "client")
        return {
          to_user: true
        }
    },
    closeDomain() {
      this.editDomainToggle = false;
      this.$api.delete("/notifications/" + this.editedDomain.id, {params: this.getDeleteNotif()})
      this.editedDomain = null;
    },
    closeArchiveConfirm() {
      this.dialogArchive = false;
      this.archivedItem = null;
    },
    closeDeleteConfirm() {
      this.dialogDelete = false;
      this.deletedItem = null;
    },
    archiveDomain(item) {
      this.dialogArchive = true;
      this.archivedItem = item;
    },
    closeValidateConfirm() {
      this.dialogValidate = false;
      this.validateItem = null;
    },
    validateDomain(item) {
      this.dialogValidate = true;
      this.validateItem = item;
    },
    deleteDomain(item) {
      this.dialogDelete = true;
      this.deletedItem = item;
    },
    editDomain(item) {
      this.editedDomain = item;
      this.domainsData.filter((domain) => {
          if (domain.id == item.id) {
            domain.to_user = false;
            domain.to_avocat = false;
          }
      });
      this.editDomainToggle = true;
    },
    viewDomain(item) {
      this.editedItemUser = item;
      this.domainsData.filter((domain) => {
          if (domain.id == item.id) {
            domain.to_user = false;
            domain.to_avocat = false;
          }
      });
      this.editDomainToggleUser = true;
    },
    validateDomainConfirm() {
      let route = this.api.domains;
      let url = "/brands/" + this.brand.id + "/domains/" + this.validateItem.id;
      
      this.$store.dispatch('put', {
        url: url,
        route: route,
        id: this.validateItem.id,
        data: { visible: true }
      }).catch((error) => {
        if (error.response.status === 403) this.$router.push("/brands");
      }).finally(() => {
        this.validateItem = null;
        this.dialogValidate = false;
        this.getDomains();
      })
    },
    archiveDomainConfirm() {
      let route = this.api.domains;
      let url = this.api.domains.url + "/" + this.archivedItem.id + "/archive";
      
      this.$store.dispatch('put', {
        url: url,
        route: route,
        data: { archive: true }
      }).catch((error) => {
        if (error.response.status === 403) this.$router.push("/brands");
      }).finally(() => {
        this.archivedItem = null;
        this.dialogArchive = false;
        this.getDomains();
      })
      this.$store.state.loading = true;
    },
    deleteDomainConfirm() {
      let id = this.$route.params.id;
      let route = this.api.brands;
      let url = "/brands/" + id + "/domains/" + this.deletedItem.id
      
      this.$store.dispatch('put', {
        url: url,
        route: route,
        id: this.deletedItem.id,
        data: { deleted_tmp: true }
      }).catch((err) => {
        console.log(err);
        if (err.response.status === 403) this.$router.push("/brands");
      }).finally(() => {
        this.dialogDelete = false;
        this.deletedItem = null;
        this.getDomains();
      })
    },
    closeAndUpdateCreate() {
      this.createDomainToggle = false;
      this.getDomains();
      this.snackBarNewDomain = true;
    },
    closeAndUpdateEdit() {
      this.editDomainToggle = false;
      this.$api.delete("/notifications/" + this.editedDomain.id, {params: this.getDeleteNotif()})
      this.editedDomain = null;

      this.getDomains();
      this.snackBarEditDomain = true;
    },
    closeAndUpdateEditAsUser() {
      this.editDomainToggleUser = false;
      this.$api.delete("/notifications/" + this.editedDomain.id, {params: this.getDeleteNotif()})
      this.editedItemUser = null;

      this.getDomains();
      this.snacbkarEditAsUser = true;
    },
    getDomains() {
      let id = this.$route.params.id;
      this.$store.state.loading = true;

      this.$api
        .get("/brands/" + id + "/domains", {params: { company_id: this.$store.state.company }})
        .then((res) => {
          this.domains = res.data;
        })
        .catch((err) => {
          if (err.response.status === 403) this.$router.push("/brands");
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },
    holderLimit(item) {
      if (item) {
        if (item.length > 24) return item.slice(0, 24) + "...";
        return item;
      }
      return "-";
    },
    holderSize(item) {
      if (item) {
        if (item.length > 24) return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}


.chip-custom {
  border-radius: 4em;
  margin-bottom: 0.5em;
}

</style>