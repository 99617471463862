<template>
  <!-- <v-card max-width="300"> -->
  <v-navigation-drawer permanent expand-on-hover app>
    <v-list nav dense>
      <v-list-item-group class="mb-6">
        <v-list-item-content> </v-list-item-content>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group
      v-model="selectedItem" color="primary"
      v-for="(group, key) in itemsNav" :key="key">
        <v-list-item
          v-for="(item, i) in group"
          :key="i"
          :disabled="item.disabled === true"
          :to="item.path"
        >
          <v-list-item-icon :disabled="item.disabled === true">
            <v-icon
              small
              v-text="item.icon"
              :color="item.disabled === true ? 'grey lighten-1' : ''"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-2 mt-3" v-if="key != Object.keys(itemsNav).length - 1"/>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
  <!-- </v-card> -->
</template>

<script>
import {
  VListItemGroup,
  // VIcon,
  VListItemContent,
  // VListItem,
  VDivider,
  VList,
  VNavigationDrawer,
  // VListItemIcon,
  // VListItemTitle,
} from "vuetify/lib";

export default {
  components: {
    VListItemGroup,
    // VIcon,
    VListItemContent,
    // VListItem,
    VDivider,
    VList,
    VNavigationDrawer,
    // VListItemIcon,
    // VListItemTitle,
  },
  data() {
    return {
      selectedItem: 0,
      currBrand: null,
      names: ["Brands", "Monitoring", "Reporting", "Classify", "Users"],
    };
  },
  created() {
    this.currBrand = this.$store.getters.getBrand;
    for (var i = 0; i < this.names.length; i++) {
      if (this.$route.name === this.names[i]) {
        this.selectedItem = i;
        break;
      } else this.selectedItem = -1;
    }
  },
  computed: {
    itemsNav() {
      let brand = this.$store.getters.getBrand;
      let cond_company = this.$store.state.company ? false : true;
      let cond = brand && cond_company == false ? false : true;
      let condperm = this.$store.getters.getRole === "client" ? true : false;
      let condAdmin = this.$store.state.user.role == "admin" ? true : false;

      let nav = {
        0: [
          {
            name: "Companies",
            text: "Entreprises",
            icon: "fas fa-building",
            disabled: false,
            path: "/companies",
          },
          {
            name: "Brands",
            text: "Marques",
            icon: "fas fa-align-left",
            disabled: cond_company,
            path: "/brands",
          },
        ],
        1: [
          {
            name: "Monitoring",
            text: "Surveillance",
            icon: "fas fa-clipboard-list",
            disabled: cond,
            path: brand ? "/monitoring/" + brand : "/monitoring",
          },
          // { name: 'Budget', text: 'Budget', icon: 'fas fa-wallet',  disabled: cond, path: '/brands'},
          // { text: '', icon: 'fa-archive',  disabled: cond, path: brand ? '/classify/' + brand : '/classify/'},
          //
          {
            name: "Classify",
            text: "Dossiers classés",
            icon: "fa-archive",
            disabled: cond,
            path: brand ? "/classify/" + brand : "/classify",
            divide: true
          }
        ],
        // { text: 'Reseau sociaux', icon: 'mdi-flag' ,  disabled: true, path: '/brands'},
        // { text: 'Utilisateurs', icon: 'fas fa-users-cog', disabled: condperm, path: '/users'},
      };

      if (condAdmin) {
        nav[1].push({
          name: "Bin",
          text: "Corbeille",
          disabled: cond,
          icon: "fa-trash",
          path: brand ? "/bin/" + brand : "/bin",
        })
      }

      nav[2] = [{
        name: "Reporting",
        text: "Reporting",
        icon: "fas fa-chart-line",
        disabled: cond,
        path: brand ? "/reporting/" + brand : "/reporting",
      }]

      if (condperm === false)
        nav[2].push({
          text: "Utilisateurs",
          icon: "fas fa-users-cog",
          disabled: condperm,
          path: "/users",
        });
      return nav;
    },
  },
  methods: {},
};
</script>