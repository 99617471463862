<template>
    <v-dialog v-if="dialog" min-width="700px" hide-overlay fullscreen transition="dialog-bottom-transition" v-model="dialog"
        persistent>
        <v-card tile>
            <v-card-title flat dark class="primary white--text">
                Création de fiche NDD
                <v-spacer />
                <ButtonCancel text="Annuler" v-on:clicked="closeNewDomain" />
                <ButtonValidate text="Sauvegarder" v-on:clicked="saveNewDomain" />
            </v-card-title>
            <v-card-text>
                <v-form v-if="form" ref="form" lazy-validation>
                    <v-row class="mt-2">
                        <!-- Informations générales -->
                        <v-col cols="4" class="grey lighten-5 px-5">
                            <v-subheader class="pl-0 pb-3">
                                <v-icon small class="mr-2 primary--text pa-1" style="border-radius: 50%">
                                    mdi-folder-information
                                </v-icon>
                                <span class="black--text font-weight-bold">Informations générales</span>
                            </v-subheader>
                            <v-row class="d-flex text-center justify-center">
                                <v-col cols="10">
                                    <v-text-field filled rounded dense :rules="[(v) => !!v || 'Nom de domaine obligatoire']"
                                        label="NDD" style="font-size: 12px !important" v-model="form.domain" />
                                </v-col>
                                <v-col cols="2">
                                    <v-btn icon :loading="domainsExistLoad" :color="checkDomainExistColor()"
                                        @click="checkDomainExist">
                                        <v-icon>mdi-check-bold</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-autocomplete dense :items="items" attach rounded outlined
                                :menu-props="{ maxHeight: '180px' }" label="État" v-model="form.color">
                                <template #selection="{ item }">
                                    <v-chip :color="getColor(item)" class="white--text"> {{ item }}</v-chip>
                                </template>
                            </v-autocomplete>
                            <v-text-field dense label="Lien" outlined rounded append-icon="mdi-open-in-new"
                                @click:append="openLink()" v-model="form.link" />
                            <v-text-field dense label="Extension" outlined rounded v-model="form.ext" />
                            <v-text-field dense label="Réf dossier" outlined rounded v-model="form.ref_folder" />
                        </v-col>
                        <!-- Panneau de controle -->
                        <v-col cols="4" class="px-5">
                            <v-subheader class="pl-0 pb-3">
                                <v-icon small class="mr-2 primary--text pa-1">mdi-cog</v-icon>
                                Panneau de contrôle
                            </v-subheader>
                            <v-row class="pa-0 ma-0">
                                <v-col cols="4" v-for="item in monitoring_options" :key="item.label">
                                    <v-switch dense flat color="success" hide-details :label="item.label"
                                        v-model="form[item.key]" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Informations complémentaires et Recommandations -->
                        <v-col cols="4" class="px-5">
                            <v-subheader class="pl-0 pb-3">
                                <v-icon small class="mr-2 primary--text pa-1">mdi-message-text</v-icon>
                                Informations & recommandations
                            </v-subheader>
                            <v-textarea outlined rows="3" dense flat label="Informations titulaire" v-model="form.holder" />
                            <v-textarea label="Exploitation" dense flat outlined rows="3" v-model="form.usage" />

                            <v-textarea filled rows="3" dense flat label="Recommandations scan" v-model="form.reco_scan" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="4" class="px-5">
                            <v-subheader class="pl-0 pb-3">
                                <v-icon small class="mr-2 primary--text pa-1">mdi-timer-sand</v-icon>
                                <span class="black--text font-weight-bold">Temporalité</span>
                            </v-subheader>
                            <v-text-field dense flat v-model="form.date_register" label="Date d'enregistrement (YYYY-MM-DD)"
                                :rules="[
                                    (v) => !v || /^\d{4}-\d{2}-\d{2}$/.test(v) || 'La date doit être au format AAAA-MM-JJ'
                                ]" clearable @click:clear="form.date_register = null" />
                            <v-text-field dense flat clearable v-model="form.date_expiration" :rules="[
                                (v) => !v || /^\d{4}-\d{2}-\d{2}$/.test(v) || 'La date doit être au format AAAA-MM-JJ'
                            ]" label="Date d'expiration (YYYY-MM-DD)" @click:clear="form.date_expiration = null" />
                            <v-text-field dense flat clearable v-model="form.date_report" :rules="[
                                (v) => !v || /^\d{4}-\d{2}-\d{2}$/.test(v) || 'La date doit être au format AAAA-MM-JJ'
                            ]" label="Date de signalement (YYYY-MM-DD)" @click:clear="form.date_report = null" />
                        </v-col>
                        <v-col cols="4" class="px-5">
                            <subheader class="pl-0 pb-3">
                                <v-icon small class="mr-2 primary--text pa-1">mdi-face-agent</v-icon>
                                <span class="black--text font-weight-bold">Instructions client</span>
                            </subheader>
                            <v-textarea dense flat outlined rows="3" label="Instructions client"
                                v-model="form.instruction" />
                            <v-textarea dense flat outlined rows="3" label="Correspondance" v-model="form.correspondance" />
                        </v-col>
                        <v-col cols="4" class="px-5">
                            <v-subheader class="pl-0 pb-3">
                                <v-icon small class="mr-2 primary--text pa-1">mdi-folder-search</v-icon>
                                <span class="black--text font-weight-bold">Suivi de dossier</span>
                            </v-subheader>
                            <v-textarea dense flat label="Suivi du dossier" outlined rows="3" v-model="form.follow" />
                            <v-textarea dense flat label="Statut final" outlined rows="3" v-model="form.final" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import ButtonValidate from "@/components/buttons/ButtonValidate";
import ButtonCancel from "@/components/buttons/ButtonCancel";


import {
    VDialog,
    VCard,
    VSpacer,
    VRow,
    VCol,
    VTextField,
    VIcon,
    VChip,
    VTextarea,
    VAutocomplete,
    VForm,
    VCardText,
} from "vuetify/lib";

export default {
    name: "CreateDomain",
    components: {
        VDialog,
        VCard,
        VSpacer,
        VRow,
        VCol,
        VTextField,
        VIcon,
        VChip,
        VTextarea,
        VAutocomplete,
        VForm,
        VCardText,
        ButtonValidate,
        ButtonCancel
    },
    props: {
        brand: String,
        dialog: Boolean,
    },
    mounted() { },
    data() {

        const defaultForm = Object.freeze({
            domain: "",
            state: "",
            link: "",
            ext: "",
            date_register: null,
            date_expiration: null,
            date_report: null,
            backorder: false,
            check_client: false,
            eval_financial: false,
            investigation: false,
            notice: false,
            notif: false,
            procedure_extra: false,
            recovery: false,
            anonyme: false,
            monitoring: false,
            holder: "",
            usage: "",
            reco_scan: "",
            instruction: "",
            rebuy: false,
            follow: "",
            color: "",
            // comment: '',
        });
        return {
            currentStep: 1,
            domainsExistLoad: false,
            form: Object.assign({}, defaultForm),
            rules: null,
            menu1: false,
            menu2: false,
            menu3: false,
            items: [
                "Dossier en cours",
                "En attente d'instruction client",
                "Monitoring seule",
                "DEC - en attente d'instruction client",
                "NDD Client",
                "Signalement pour info",
            ],
            value: [],
            monitoring_options: [
                {
                    "label": "Monitoring",
                    "key": "monitoring"
                },
                {
                    "label": "Vérification client",
                    "key": "check_client"
                },
                {
                    "label": "Investigations SCAN",
                    "key": "investigation"
                },
                {
                    "label": "Levée d'anonymat",
                    "key": "anonyme"
                },
                {
                    "label": "Mise en demeure",
                    "key": "notice"
                },
                {
                    "label": "Notification",
                    "key": "notif"
                },
                {
                    "label": "Procédure extrajudiciaire",
                    "key": "procedure_extra"
                },
                {
                    "label": "Récupération à l'amiable",
                    "key": "recovery"
                },
                {
                    "label": "Evaluation financière",
                    "key": "eval_financial"
                },
                {
                    "label": "Backorder",
                    "key": "backorder"
                },
                {
                    "label": "Rachat",
                    "key": "rebuy"
                },
            ]
        };
    },
    created() {
        this.form.date_report = this.formatDate(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000));
    },
    computed: {
    },
    methods: {
        checkDomainExistColor() {
            if (this.domainsExist === false) return "green";
            if (this.domainsExist) return "red";
            return "grey";
        },
        openLink() {
            if (!this.form || !this.form.link) return;

            let url = this.form.link;

            if (!url.match(/^https?:\/\//i)) {
                url = 'http://' + url;
            }
            window.open(url, "_blank");
        },
        formatDate: function (item, val) {
            let date;
            if (item) date = new Date(item);
            else date = Date.now();
            let year;
            if (val) year = date.getFullYear() + 1;
            else year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();

            if (dt < 10) {
                dt = "0" + dt;
            }
            if (month < 10) {
                month = "0" + month;
            }
            return year + "-" + month + "-" + dt;
        },
        getColor(item) {
            if (item) {
                item = item.toLowerCase();
                if (item == "dossier en cours") return "orange lighten-1";
                if (item == "en attente d'instruction client")
                    return "deep-purple lighten-1";
                if (item == "monitoring seule") return "yellow lighten-1";
                if (item == "dec - en attente d'instruction client")
                    return "blue lighten-1";
                if (item == "ndd client") return "green lighten-1";
                if (item == "signalement pour info") return "blue-grey lighten-1";
            }
            return "grey lighten-1";
        },
        saveNewDomain() {
            if (this.$refs.form.validate()) {
                this.formatForm(this.form);
                this.$api
                    .post("/brands/" + this.brand + "/domains", this.form)
                    .then(() => {
                        this.createdDomainSuccess();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$emit("createError");
                    })
                    .finally(() => {
                        this.form = Object.assign({}, this.defaultForm);
                        this.form.date_report = this.formatDate(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000));
                        this.currentStep = 1;
                        this.closeNewDomain();
                    });
            }
        },
        formatForm(form) {
            if (form.date_register)
                form.date_register = this.formatDate(form.date_register, false);
            if (form.date_expiration)
                form.date_expiration = this.formatDate(form.date_expiration, false);
            else {
                if (form.date_register)
                    form.date_expiration = this.formatDate(form.date_register, true);
            }
            if (form.date_report)
                form.date_report = this.formatDate(form.date_report, false);
        },
        closeNewDomain() {
            if (this.$refs.form)
                this.$refs.form.resetValidation();
            this.currentStep = 1;
            this.form = Object.assign({}, this.defaultForm);
            this.form.date_report = this.formatDate(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000));
            this.$emit("closeDomainCreate", "Closed!");
        },
        createdDomainSuccess() {
            this.$emit("createdDomainSuccess");
        },
        checkDomainExist() {
            this.domainsExistLoad = true;
            this.$api.get('/domains?s=' + this.form.domain)
                .then((res) => {
                    if (res.data.rowCount === 1)
                        this.domainsExist = true
                    else
                        this.domainsExist = false;
                    setTimeout(() => {
                        this.domainsExistLoad = false;
                    }, 1000);
                })
        }
    },
};
</script>


<style lang="scss" scoped>
.little-bold-text {
    font-weight: 500;
}

::v-deep .v-label {
    font-size: 12px
}

::v-deep .v-label--active {
    font-size: 12px
}

::v-deep input {
    font-size: 12px
}

::v-deep .v-list-item__title {
    font-size: 12px
}

::v-deep .v-chip__content {
    font-size: 12px
}

::v-deep textarea {
    font-size: 12px
}
</style>
