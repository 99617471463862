export default function MonitoringUserHeader() {
    return [
        {
            text: "NDD",
            align: "start",
            sortable: true,
            value: "domain",
            filterable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        { text: "DATE", value: "date_report", filterLabel: 'Date de signalement', sortable: true, filterable: true},
        {
            text: "TITULAIRE",
            value: "holder",
            align: "start",
            filterable: true,
            sortable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"],
        },
        {
            text: "EXPLOITATION",
            value: "usage",
            align: "start",
            filterable: true,
            sortable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        {
            text: "RECOMMANDATIONS",
            value: "reco_scan",
            align: "start",
            filterable: true,
            sortable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        {
            text: "INSTRUCTIONS",
            value: "instruction",
            align: "start",
            filterable: true,
            sortable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        { text: "SUIVI", value: "follow", align: "start", sortable: true, class: ["pr-0 pl-1"] },
        {
            text: "ETAT",
            value: "color",
            sortable: true,
            align: "start",
            filterable: true,
            filterLabel: 'Recherche',
            width: '60px',
            class: ["pr-0 pl-1"]
        },
        {
            text: "",
            value: "edit",
            sortable: false,
            align: "start",
            class: ["pr-0", "pl-0"],
            cellClass: ["pl-0", "pr-0"],
            filterable: false,
        },
    ];
}