export default {
    data() {
        return {
            search: "",
            filters: {
                domain: "",
                date_report: "",
                holder: "",
                usage: "",
                reco_scan: "",
                instruction: "",
                follow: "",
                color: "",
            },
        }
    },
    created: function () {

    },
    methods: {
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] === "date_report") {
                    let dateA = new Date(a[index]);
                    let dateB = new Date(b[index]);
                    if (!isDesc[0]) {
                        return dateB - dateA;
                    } else {
                        return dateA - dateB;
                    }
                } else {
                    if (!isDesc[0]) {
                        return a[index] < b[index] ? -1 : 1;
                    } else {
                        return b[index] < a[index] ? -1 : 1;
                    }
                }
            });
            return items;
        },
        getFiltersFunc() {
            let funcs = {
                domain: this.filterNddName,
                date_report: this.filterDate,
                holder: this.filterTitulaire,
                usage: this.filterExploitation,
                reco_scan: this.filterReco,
                instruction: this.filterInstruc,
                follow: this.filterSuivi,
                color: this.filterState,
            };
            return funcs;
        },
        filterNddName(item) {
            if (item.domain)
                return item.domain
                    .toLowerCase()
                    .includes(this.filters["domain"].toLowerCase());
            return false;
        },
        filterDate(item) {
            if (item.date_report)
                return this.formatDate(item.date_report)
                    .toLowerCase()
                    .includes(this.filters["date_report"].toLowerCase());
            return false;
        },
        filterTitulaire(item) {
            if (item.holder)
                return item.holder
                    .toLowerCase()
                    .includes(this.filters["holder"].toLowerCase());
            return false;
        },
        filterExploitation(item) {
            if (item.usage)
                return item.usage
                    .toLowerCase()
                    .includes(this.filters["usage"].toLowerCase());
            return false;
        },
        filterReco(item) {
            if (item.reco_scan)
                return item.reco_scan
                    .toLowerCase()
                    .includes(this.filters["reco_scan"].toLowerCase());
            return false;
        },
        filterInstruc(item) {
            if (item.instruction)
                return item.instruction
                    .toLowerCase()
                    .includes(this.filters["instruction"].toLowerCase());
            return item;
        },
        filterSuivi(item) {
            if (item.follow)
                return item.follow
                    .toLowerCase()
                    .includes(this.filters["follow"].toLowerCase());
            return false;
        },
        filterState(item) {
            if (item.color)
                return item.color
                    .toLowerCase()
                    .includes(this.filters["color"].toLowerCase());
            return false;
        },
    }
}