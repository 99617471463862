import Vue from 'vue'
import VueRouter from 'vue-router'
import Brands from '../views/Brands.vue'
import Companies from '../views/Companies.vue'
import Login from '../views/Login.vue'
// import User from '../views/User.vue'
import Users from '../views/Users.vue'
// import UserProfile from '../views/UserProfile.vue'
// import UserProfileEdit from '../views/UserProfileEdit.vue'
import Monitoring from '../views/Monitoring.vue'
import Reporting from '../views/Reporting.vue'
import Classify from '../views/Classify.vue'
import Bin from '../views/Bin.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      Auth: false,
      title: 'SCAN avocats : Authentification'
    }
  },
  {
    path: '/brands',
    name: 'Brands',
    component: Brands,
    meta: {
      Auth: true,
      title: 'SCAN avocats : Marques'
    }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
    meta: {
      Auth: true,
      title: 'SCAN avocats : Entreprises'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      Auth: true,
      has_perm: true,
      title: 'SCAN avocats : Utilisateurs'
    }
  },
  // {
  //   path: '/user/:id',
  //   component: User,
  //   children: [
  //     { path: '', component: UserProfile },
  //     {
  //       path: 'edit',
  //       component: UserProfileEdit
  //     }
  //   ],
  //   meta: {
  //     Auth: true,
  //     title: 'SCAN avocats : Utilisateurs édition'
  //   }
  // },
  {
    path: '/monitoring/:id',
    name: 'Monitoring',
    component: Monitoring,
    meta: {
      Auth: true,
      Brand: true,
      title: 'SCAN avocats : Surveillance'
    }
  },
  {
    path: '/reporting/:id',
    name: 'Reporting',
    component: Reporting,
    meta: {
      Auth: true,
      Brand: true,
      title: 'SCAN avocats : reporting'
    }
  },
  {
    path: '/classify/:id',
    name: 'Classify',
    component: Classify,
    meta: {
      Auth: true,
      Brand: true,
      title: 'SCAN avocats : Surveillance classées'
    }
  },
  {
    path: '/bin/:id',
    name: 'Bin',
    component: Bin,
    meta: {
      Auth: true,
      Brand: true,
      title: 'SCAN avocats : Corbeille'
    }
  },
  { path: "*", component: PageNotFound }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  const store = await import('@/store');
  const user = store.default.getters.getUser
  if (to.matched.some((record) => record.meta.Auth)) {
    if (user === null) {
      next({ path: '/' })
    }
    if (to.matched.some((record) => record.meta.Brand)) {
      let brand = store.default.getters.getBrand
      if (brand === null)
        next({ path: '/brands' })
      next();
    }
    if (to.matched.some((record) => record.meta.has_perm)) {
      if (user.role === 'client')
        next({ path: '/brands' })
      next();
    }
    next();
  }
  else {
    const token = store.default.getters.getToken
    if (token)
      next({ path: '/brands' })
    next()
  }
})

const DEFAULT_TITLE = 'Scan-avocats';

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router
