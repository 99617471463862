<template>
    <v-btn
        v-if="condition"
        dark fab small
        bottom
        color="red"
        class="ma-1"
        @click="$emit('clicked')">
            <v-icon dark small> fas fa-trash </v-icon>
        </v-btn>
</template>

<script>
export default {
    name: "ButtonDelete",
    props: {
        condition: {
            type: Boolean,
            required: true
        }
    }
}
</script>