<template>
    <v-footer padless class="mt-10">
      <v-card flat tile class="grey lighten-4 text-center" width="100%">
        <v-card-text>
          {{ new Date().getFullYear() }} — <strong>Scan Avocats</strong>
          <span> all rights preserved</span>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
export default {
	name: "Footer",
	data: () => ({
	}),
};
</script>
