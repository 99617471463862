<template>
  <canvas ref="myChart" width="600" height="350"></canvas>
</template>

<script>

import Chart from 'chart.js/auto';
export default {
  name: 'line-chart',
  props: {
    // The chart's data.datasets
    datasets: {
      type: Object,
      required: true
    },
    // The chart's options.
    options: Object
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.chart = new Chart(this.$refs.myChart, {
      type: 'doughnut',
      data: this.datasets,
      options: this.options
    });
  },
  beforeDestroy () {
    // Don't forget to destroy the Chart.js instance.
    if (this.chart) {
      this.chart.destroy()
    }
  }
}
</script>