import { mapState } from "vuex";

const MapStore = {
    computed: {
        ...mapState({
            api: module => module.api,
            loading: state => state.loading,
            isAdmin: state => state.user.role === "admin" ? true : false,
            isAvocat: state => state.user.role === "avocat" ? true : false,
        }),
    
        rulesLogin() {
            const rules = [];
    
            const notEmpty = (v) => (v || "").length > 0 || "Champ requis";
            const validLogin = (v) =>
            /^[a-z\d\-_\s]+$/i.test(v) || "Character valide a-z A-Z 0-9";
            rules.push(notEmpty);
            rules.push(validLogin);
            return rules;
        },
    },
    methods: {
        formatDate: function (item) {
            if (!item) {
                return "-"
            }
            let date = new Date(item);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();
        
            if (dt < 10) {
              dt = "0" + dt;
            } if (month < 10) {
              month = "0" + month;
            }
        
            return year + "-" + month + "-" + dt;
        },
        getColor(item) {
            if (item) {
                item = item.toLowerCase();
                if (item == "dossier en cours") return "orange lighten-1";
                if (item == "en attente d'instruction client") return "deep-purple lighten-1";
                if (item == "monitoring seule") return "yellow lighten-1";
                if (item == "dec - en attente d'instruction client") return "blue lighten-1";
                if (item == "ndd client") return "green lighten-1";
                if (item == "signalement pour info") return "blue-grey lighten-1";
                return "grey lighten-1";
            }
            return "grey lighten-1";
          },
    }
}

export default MapStore;