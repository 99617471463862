export default function monitoringHeaderAll() {
    return [
        {
            text: "NDD",
            align: "start",
            sortable: true,
            value: "domain",
            filterable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        {
            text: "DATE",
            value: "date_report",
            sortable: true,
            filterable: true,
            align: "start",
            filterLabel: 'Date de signalement',
            class: ["pr-0 pl-1"],
            width: '7rem'
        },
        {
            text: "TITULAIRE",
            value: "holder",
            align: "start",
            filterable: true,
            sortable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        {
            text: "EXPLOITATION",
            value: "usage",
            align: "start",
            filterable: true,
            sortable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        {
            text: "RECO SCAN",
            value: "reco_scan",
            align: "start",
            filterable: true,
            sortable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        {
            text: "INST CLIENT",
            value: "instruction",
            align: "start",
            filterable: true,
            sortable: true,
            filterLabel: 'Recherche',
            class: ["pr-0 pl-1"]
        },
        { text: "SUIVI", value: "follow", align: "start", sortable: true, filterable: true, filterLabel: 'Recherche', class: ["pr-0 pl-1"] },
        { text: "ETAT", value: "color", sortable: true, align: "start", filterable: true, filterLabel: 'Recherche', class: ["pr-0 pl-1"], width: '60px', },
        {
            text: "",
            value: "edit",
            sortable: false,
            align: "start",
            class: ["pr-0", "pl-0"],
            cellClass: ["pl-0", "pr-0"],
            filterable: false,
        },
    ]
}