var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('NavBarLeft'),(_vm.domains)?_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.domainsHeader,"items":_vm.domainsData,"item-key":"id","expanded":_vm.expanded,"loading":_vm.loading,"footer-props":_vm.pageProps,"search":_vm.search,"custom-sort":_vm.customSort},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('DashboardTitle',{attrs:{"text":'CORBEILLE ' + _vm.brand.name}})],1),_c('p',{staticClass:"ml-4",staticStyle:{"width":"20%"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Recherche","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('p',{staticClass:"ml-4 font-italic text-left caption"},[_vm._v(" Le tableau est trié par défaut par date de signalement. ")])]},proxy:true},_vm._l((_vm.domainsHeader),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [(header.filterable)?_c('v-menu',{key:header.value,attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","elevation":"0","color":_vm.filters[header.value] ? 'primary' : ''}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"clearable":"","type":"text","label":header.filterLabel},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}})],1)]):_vm._e()]}}}),{key:"item.domain",fn:function(ref){
var item = ref.item;
return [(_vm.holderSize(item.domain))?_c('span',[_vm._v(" "+_vm._s(_vm.holderLimit(item.domain))+" ")]):_c('span',[_vm._v(_vm._s(item.domain ? item.domain : "-"))])]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getColor(item.color),"dark":"","small":""}},'v-chip',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.color ? item.color : "Autres"))])])]}},{key:"item.holder",fn:function(ref){
var item = ref.item;
return [(_vm.holderSize(item.holder))?_c('span',[_vm._v(" "+_vm._s(_vm.holderLimit(item.holder))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.holder)+" ")])]}},{key:"item.follow",fn:function(ref){
var item = ref.item;
return [(_vm.holderSize(item.follow))?_c('span',[_vm._v(" "+_vm._s(_vm.holderLimit(item.follow))+" ")]):_c('span',[_vm._v(_vm._s(item.follow ? item.follow : "-"))])]}},{key:"item.date_report",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date_report)))])]}},{key:"item.instruction",fn:function(ref){
var item = ref.item;
return [(_vm.holderSize(item.instruction))?_c('span',[_vm._v(" "+_vm._s(_vm.holderLimit(item.instruction))+" ")]):_c('span',[_vm._v(_vm._s(item.instruction ? item.instruction : "-"))])]}},{key:"item.reco_scan",fn:function(ref){
var item = ref.item;
return [(_vm.holderSize(item.reco_scan))?_c('span',[_vm._v(" "+_vm._s(_vm.holderLimit(item.reco_scan))+" ")]):_c('span',[_vm._v(_vm._s(item.reco_scan ? item.reco_scan : "-"))])]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [(_vm.holderSize(item.usage))?_c('span',[_vm._v(" "+_vm._s(_vm.holderLimit(item.usage))+" ")]):_c('span',[_vm._v(_vm._s(item.usage ? item.usage : "-"))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"value":!item.visible,"color":"red","dot":"","offset-x":"10","offset-y":"10"}},[_c('HandleDomainMenu',{attrs:{"isAvocat":_vm.isAvocat,"isAdmin":_vm.isAdmin,"visible":item.visible,"bin":""},on:{"view":function($event){return _vm.viewDomain(item)},"delete":function($event){return _vm.deleteDomain(item)},"unbin":function($event){return _vm.unbinDomain(item)}}})],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.domainsHeader),function(col,key){return _c('td',{key:key,staticClass:"grey lighten-5 pa-0",staticStyle:{"vertical-align":"top","border-bottom":"1px solid"},attrs:{"colspan":item.length}},[(item[col.value] && ['date_report', 'data-table-expand', 'edit', 'color', 'holder'].includes(col.value) == false)?_c('v-sheet',{staticClass:"transparent px-4",attrs:{"flat":"","max-length":item.length}},[_c('v-card-title',{staticClass:"pa-0 pt-0 pb-0 pl-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(col.text)+" ")]),_c('v-card-text',{staticClass:"pa-0 pt-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item[col.value])+" ")])],1):_vm._e()],1)})}}],null,true)}):_vm._e(),_c('v-fab-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.windowTop),expression:"windowTop"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticStyle:{"margin-bottom":"60px","margin-right":"20px"},attrs:{"fixed":"","right":"","bottom":"","fab":"","large":"","color":"primary"},on:{"click":function($event){return _vm.$vuetify.goTo(0, _vm.options)}}},[_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{staticClass:"py-4"},[_c('v-card-title',[_c('v-spacer'),(_vm.deletedItem)?_c('DashboardTitle',{attrs:{"text":'Supprimer le domaine ' + _vm.deletedItem.domain + ' ?'}}):_vm._e(),_c('v-spacer')],1),_c('v-card-actions',[_c('v-spacer'),_c('ButtonCancel',{attrs:{"condition":false},on:{"clicked":_vm.closeDeleteConfirm}}),_c('ButtonValidate',{attrs:{"condition":false},on:{"clicked":_vm.deleteDomainConfirm}}),_c('v-spacer')],1)],1)],1),(_vm.viewItem)?_c('EditDomain',{attrs:{"noEdition":true,"dialog":_vm.viewDomainToggle,"brand":this.$route.params.id,"domain":_vm.viewItem},on:{"closeDomain":function($event){_vm.viewDomainToggle = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }