<template>
    <v-btn
    depressed color="success" class="justify-center ml-1"
    :disabled="disabled"
    @click="$emit('clicked')">
        <span style="font-size: 12px !important;">
            {{ text ? text : 'Valider' }}
        </span>
    </v-btn>
</template>

<script>
export default {
    name: "ButtonValidate",
    props: {
        disabled: {
            type: Boolean,
        },
        text: {
            type: String
        }
    }
}
</script>