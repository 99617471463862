<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" small icon v-on="on" v-bind="attrs">
                <v-icon>
                    mdi-dots-vertical
                </v-icon>
            </v-btn>
        </template>
        <v-sheet min-width="210px">
            <v-list dense>
                <v-list-item-group color="primary">
                    <v-list-item v-if="!classify && !bin" class="pa-0 pl-1" @click="$emit('edit')">
                        <v-badge :value="changed" color="red" dot inline left>
                            <v-subheader class="pa-0">
                                <v-btn color="primary" dark small icon>
                                    <v-icon small dark>
                                        fas fa-edit
                                    </v-icon>
                                </v-btn>
                                Éditer
                            </v-subheader>
                        </v-badge>
                    </v-list-item>

                    <v-list-item class="pa-0 pl-3" v-if="classify || bin" @click="$emit('view')">
                        <v-subheader class="pa-0">
                            <v-btn color="primary" dark small icon>
                                <v-icon small dark>
                                    fas fa-eye
                                </v-icon>
                            </v-btn>
                            Consulter
                        </v-subheader>
                    </v-list-item>

                    <v-list-item class="pa-0 pl-3" v-if="(isAdmin || isAvocat)" @click="$emit('delete')">
                        <v-subheader class="pa-0">
                            <v-btn color="error" dark small icon>
                                <v-icon small dark>
                                    fas fa-trash-alt
                                </v-icon>
                            </v-btn>
                            Supprimer
                        </v-subheader>
                    </v-list-item>

                    <v-list-item class="pa-0 pl-3" v-if="(isAdmin || isAvocat) && !classify && !bin"
                        @click="$emit('archive')">
                        <v-subheader class="pa-0">
                            <v-btn color="success" dark small icon>
                                <v-icon small dark>
                                    fas fa-archive
                                </v-icon>
                            </v-btn>
                            Archiver
                        </v-subheader>
                    </v-list-item>

                    <v-list-item class="pa-0 pl-3" v-if="(isAdmin || isAvocat) && classify" @click="$emit('unarchive')">
                        <v-subheader class="pa-0">
                            <v-btn color="success" dark small icon>
                                <v-icon small dark>
                                    fas fa-arrow-up
                                </v-icon>
                            </v-btn>
                            Désarchiver
                        </v-subheader>
                    </v-list-item>

                    <v-list-item class="pa-0 pl-3" v-if="bin" @click="$emit('unbin')">
                        <v-subheader class="pa-0">
                            <v-btn color="success" dark small icon>
                                <v-icon small dark>
                                    fas fa-arrow-up
                                </v-icon>
                            </v-btn>
                            Sortir de la corbeille
                        </v-subheader>
                    </v-list-item>

                    <v-list-item class="pa-0 pl-3 grey lighten-4" v-if="isAdmin && !visible && !classify && !bin"
                        @click="$emit('validate')">
                        <v-badge :value="!visible" color="red" dot inline left>
                            <v-subheader class="pa-0 pl-1" style="font-weight: 500">
                                Valider ce nouveau domain
                            </v-subheader>
                        </v-badge>
                    </v-list-item>

                </v-list-item-group>
            </v-list>
        </v-sheet>
    </v-menu>
</template>

<script>
export default {
    name: "HandleDomainMenu",
    props: {
        isAvocat: {
            type: Boolean,
            required: true
        },
        isAdmin: {
            type: Boolean,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        classify: {
            type: Boolean,
            required: false
        },
        bin: {
            type: Boolean,
            required: false
        },
        changed: {
            type: Boolean,
            default: false
        }
    }
}
</script>