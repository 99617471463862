<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container fluid>
    <NavBarLeft />
    <v-data-table
      v-if="domains"
      class="row-pointer"
      :headers="domainsHeader"
      :items="domainsData"
      item-key="id"
      :expanded.sync="expanded"
      :footer-props="pageProps"
      :loading="loading"
      :search="search"
      @click:row="handleClickRow">
    >
      <template v-slot:top>
        <v-toolbar flat>
          <DashboardTitle
          :text="'ARCHIVAGE ' + brand[0].name"/>
        </v-toolbar>
        <v-row>
          <v-col cols="4">
            <p style="width: 75%" class="ml-4">
              <v-text-field
                v-model="search"
                append-icon="fas fa-search"
                label="Recherche"
                hide-details
              ></v-text-field>
            </p>
            <p class="ml-4 font-italic text-left caption">
              Le tableau est trié par défaut aux dernières créations
            </p>
          </v-col>
          <v-spacer></v-spacer>
        <v-col cols="2 mb-4">
          <div class="d-flex flex-column">
            <div class="pa-2 pl-4 pr-4 orange lighten-1 chip-custom">Dossier en cours</div>
            <div class="pa-2 pl-4 pr-4 deep-purple lighten-1 chip-custom">En attente d'instruction client</div>
          </div>
        </v-col>
        <v-col cols="2">
          <div class="d-flex flex-column">
            <div class="pa-2 pl-4 pr-4 yellow lighten-1 chip-custom">Monitoring seule</div>
            <div class="pa-2 pl-4 pr-4 blue lighten-1 chip-custom">DEC - En attente d'instruction client</div>
          </div>
        </v-col>
        <v-col cols="2 mb-4">
          <div class="d-flex flex-column">
            <div class="pa-2 pl-4 pr-4 green lighten-1 chip-custom">NDD Client</div>
            <div class="pa-2 pl-4 pr-4 blue-grey lighten-1 chip-custom">Signalement pour info</div>
          </div>
        </v-col>
        </v-row>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Vous allez supprimer</v-card-title>
            <v-card-text v-if="deletedItem" class="text-left">
              {{ deletedItem.domain }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteConfirm"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteDomainConfirm"
                >Confirmer</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogArchive" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Êtes-vous sûr de désarchiver ?</v-card-title
            >
            <v-card-text v-if="archivedItem" class="text-left">
              {{ archivedItem.domain }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeArchiveConfirm"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click="unarchiveDomainConfirm"
                >Confirmer</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-for="header in domainsHeader" v-slot:[`header.${header.value}`]="{ header }">
        <span :key="header.name">{{ header.text }}</span>
        <v-menu
          v-if="header.filterable"
          :key="header.value"
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon v-bind="attrs" v-on="on">
              <v-icon small :color="filters[header.value] ? 'primary' : ''">
                fas fa-filter
              </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px">
            <v-text-field
              v-model="filters[header.value]"
              class="pa-4"
              type="text"
              :label="header.filterLabel"
              clearable
            ></v-text-field>
          </div>
        </v-menu>
      </template>
      <template v-slot:item.color="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :color="getColor(item.color)"
              dark
              small
              v-bind="attrs"
              v-on="on"
            >
            </v-chip>
          </template>
          <span>{{ item.color ? item.color : "Autres" }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.holder="{ item }">
        <span v-if="holderSize(item.holder)">
          {{ holderLimit(item.holder) }}
        </span>
        <span v-else>
          {{ item.holder }}
        </span>
      </template>
      <template v-slot:item.date_report="{ item }">
        <span>{{ formatDate(item.date_report) }}</span>
      </template>
      <template v-slot:item.follow="{ item }">
        <span v-if="holderSize(item.follow)">{{
          holderLimit(item.follow)
        }}</span>
        <span v-else>{{ item.follow ? item.follow : "-" }}</span>
      </template>
      <template v-slot:item.instruction="{ item }">
        <span v-if="holderSize(item.instruction)">{{
          holderLimit(item.instruction)
        }}</span>
        <span v-else>{{ item.instruction ? item.instruction : "-" }}</span>
      </template>
      <template v-slot:item.reco_scan="{ item }">
        <span v-if="holderSize(item.reco_scan)">{{
          holderLimit(item.reco_scan)
        }}</span>
        <span v-else>{{ item.reco_scan ? item.reco_scan : "-" }}</span>
      </template>
      <template v-slot:item.usage="{ item }">
        <span v-if="holderSize(item.usage)">{{ holderLimit(item.usage) }}</span>
        <span v-else>{{ item.usage ? item.usage : "-" }}</span>
      </template>

      <template v-slot:item.edit="{ item }">
          <HandleDomainMenu
          :isAvocat="isAvocat"
          :isAdmin="isAdmin"
          :visible="item.visible"
          :classify="true"
          v-on:view="viewDomain(item)"
          v-on:delete="deleteDomain(item)"
          v-on:unarchive="Unarchive(item)"/>
      </template>
      <template v-slot:expanded-item="{ item }">
			<td :colspan="item.length" class="grey lighten-5 pa-0" style="vertical-align: top; border-bottom: 1px solid" v-for="(col, key) in domainsHeader" :key="key">
				<v-sheet flat class="transparent px-4"  :max-length="item.length" v-if="item[col.value] && ['date_report', 'data-table-expand', 'edit', 'color', 'holder'].includes(col.value) == false">
					<v-card-title style="font-size: 12px" class="pa-0 pt-0 pb-0 pl-0"> {{ col.text }} </v-card-title>
					<v-card-text style="font-size: 12px" class="pa-0 pt-0"> {{ item[col.value] }} </v-card-text>
				</v-sheet>
			</td>
		</template>
      <template slot="no-data"> Pas de données disponible. </template>
    </v-data-table>

    <v-fab-transition>
      <v-btn fixed right bottom fab large
      style="margin-bottom: 60px; margin-right: 20px;"
      v-show="windowTop"
      v-scroll="onScroll"
      @click="$vuetify.goTo(0, options)" color="primary">
        <v-icon>
          mdi-chevron-up
        </v-icon>
      </v-btn>
    </v-fab-transition>

    <EditDomain
      v-if="viewItem"
      :noEdition="true"
      :dialog="viewDomainToggle"
      :brand="this.$route.params.id"
      :domain="viewItem"
      v-on:closeDomain="viewDomainToggle = false"/>

    <v-snackbar v-model="snackBarNewDomain" timeout="2000" color="success">
      Création réussie
    </v-snackbar>
    <v-snackbar v-model="snackBarDeleteDomain" timeout="2000" color="success">
      Suppression réussie
    </v-snackbar>
    <v-snackbar v-model="snackbarArchiveDomain" timeout="2000" color="success">
      Désarchivage réussi
    </v-snackbar>
  </v-container>
</template>

<script>
import NavBarLeft from "@/components/NavBarLeft.vue";
import EditDomain from "../components/EditDomain.vue";
// import EditionDomainNewMenu from "../components/EditionDomainNewMenu.vue";


import ClassifyHeader from "../helpers/ClassifyHeader";
import ClassifyUserHeader from "../helpers/ClassifyUserHeader";
import FilterMonitoring from "../mixins/FilterMonitoring";
import MapStore from "@/mixins/MapStore";

import DashboardTitle from "@/components/texts/DashboardTitle";
import HandleDomainMenu from "@/components/menus/HandleDomainMenu";

import {
  VDialog,
  VCard,
  VToolbar,
  VSpacer,
  VCardActions,
  VRow,
  VCol,
  VTextField,
  VBtn,
  VCardTitle,
  VDataTable,
  VIcon,
  VTooltip,
  VMenu,
  VChip,
  VContainer,
  VSnackbar,
  VCardText,
} from "vuetify/lib";

export default {
  name: "Monitoring",
  components: {
    NavBarLeft,
    EditDomain,
    VDialog,
    VCard,
    VToolbar,
    VSpacer,
    VCardActions,
    VRow,
    VCol,
    VTextField,
    VBtn,
    VCardTitle,
    VDataTable,
    VIcon,
    VTooltip,
    VMenu,
    VChip,
    VContainer,
    VSnackbar,
    VCardText,
    DashboardTitle,
    HandleDomainMenu,
    // EditionDomainNewMenu
  },
  mixins: [ FilterMonitoring, MapStore ],
  data() {
    return {
      expanded: [],
      isAdmin: false,
      createDomainToggle: false,
      editDomainToggle: false,
      snackBarNewDomain: false,
      snackBarDeleteDomain: false,
      snackbarArchiveDomain: false,
      brand: null,
      domains: null,
      editedItem: null,
      deletedItem: null,
      archivedItem: null,
      loading: false,
      dialogDelete: false,
      dialogArchive: false,
      viewItem: null,
      viewDomainToggle: false,
      windowTop: false,
    };
  },
  created() {
    let id = this.$route.params.id;
    this.$api.get("/brands/" + id).then((res) => {
      this.brand = res.data;
    });
    this.getDomains();
    if (this.$store.getters.getUser.role != "client") this.isAdmin = true;
  },
  computed: {
    options () {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      }
    },
    pageProps() {
      return { itemsPerPageOptions: [100, 50, 15] };
    },
    domainsData() {
      let conditions = [];
      let funcs = this.getFiltersFunc();
      for (const property in this.filters) {
        if (this.filters[property]) conditions.push(funcs[property]);
      }
      if (conditions.length > 0) {
        return this.domains.filter((domain) => {
          return conditions.every((condition) => {
            return condition(domain);
          });
        });
      }
      return this.domains;
    },
    brandSelect() {
      return this.brands.map((brand) => brand.name);
    },
    domainsHeader() {
      let val = this.isAdmin ? true : false;
      if (val) {
        return ClassifyHeader();
      } else {
        return ClassifyUserHeader();
      }
    },
  },
  methods: {
		handleClickRow(event) {
			let index = this.expanded.indexOf(event, 0);
	
			if (index != -1) {
				this.expanded.splice(index, 1);
			} else {
				this.expanded.push(event);
			}
		},
    onScroll () {
      const top = (
        window.pageYOffset ||
        document.documentElement.offsetTop ||
        0
      )
      this.windowTop = top > 300
    },
    formatDate: function (item) {
      if (!item) return "-";

      let date = new Date(item);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      // return dt + '-' + month + '-' + year
      return year + "-" + month + "-" + dt;
    },
    closeArchiveConfirm() {
      this.dialogArchive = false;
      this.archivedItem = null;
    },
    closeDeleteConfirm() {
      this.dialogDelete = false;
      this.deletedItem = null;
    },
    Unarchive(item) {
      this.dialogArchive = true;
      this.archivedItem = item;
    },
    deleteDomain(item) {
      this.dialogDelete = true;
      this.deletedItem = item;
    },
    unarchiveDomainConfirm() {
      this.loading = true;
      this.$api
        .put("/domains/" + this.archivedItem.id + "/archive", {
          archive: false,
        })
        .then(() => {
          this.snackbarArchiveDomain = true;
        })
        .finally(() => {
          this.loading = false;
          this.archivedItem = null;
          this.dialogArchive = false;
          this.getDomains();
        });
    },
    deleteDomainConfirm() {
      let id = this.$route.params.id;
      this.loading = true;
      this.$api
        .put("/brands/" + id + "/domains/" + this.deletedItem.id, { deleted_tmp: true })
        .then(() => {
          this.snackBarDeleteDomain = true;
        })
        .finally(() => {
          this.loading = false;
          this.deletedItem = null;
          this.dialogDelete = false;
          this.getDomains();
        });
    },
    closeAndUpdate() {
      this.createDomainToggle = false;
      this.editDomainToggle = false;
      this.editedItem = null;
      this.getDomains();
      this.snackBarNewDomain = true;
    },
    getDomains() {
      let id = this.$route.params.id;
      this.loading = true;
      this.$api
        .get("/brands/" + id + "/darchived")
        .then((res) => {
          this.domains = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.domains = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewDomain(item) {
      this.viewItem = item;
      this.viewDomainToggle = true;
    },
    closeView() {
      (this.viewItem = null), (this.viewDomainToggle = false);
    },
    holderLimit(item) {
      if (item) {
        if (item.length > 24) return item.slice(0, 24) + "...";
        return item;
      }
      return "-";
    },
    holderSize(item) {
      if (item) {
        if (item.length > 24) return true;
        return false;
      }
    },
  },
};
</script>

<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.chip-custom {
  border-radius: 4em;
  margin-bottom: 0.5em;
}
</style>