<template>
	<!--
	Edition de domaines en tant qu'avocat et ou admin.
-->
	<v-dialog min-width="700px" scrollable class="create-dialog" fullscreen hide-overlay
		transition="dialog-bottom-transition" v-model="dialog" v-if="dialog" persistent>
		<v-card tile>
			<v-card-title flat dark class="primary white--text">
				{{ !noEdition ? "Édition du domaine" : "Fiche du domaine" }}
				<v-spacer />
				<ButtonCancel text="Annuler" v-on:clicked="$emit('closeDomain', 'Closed!')" />
				<ButtonValidate v-if="!noEdition" text="Sauvegarder" v-on:clicked="saveDomain" />
			</v-card-title>
			<v-card-text>
				<v-form v-if="form" ref="form" lazy-validation>
					<v-row class="mt-2">
						<v-col cols="4" class="grey lighten-5 px-5">
							<v-subheader class="pl-0 pb-3">
								<v-icon small class="mr-2 primary--text grey lighten-3 pa-1" style="border-radius: 50%">
									mdi-folder-information
								</v-icon>
								Informations générales
							</v-subheader>
							<v-row class="d-flex text-center justify-center">
								<v-col cols="10">
									<v-text-field filled rounded dense :disabled="userRole == 'client' || noEdition"
										:rules="[(v) => !!v || 'Nom de domaine obligatoire']" label="NDD"
										style="font-size: 12px !important" v-model="form.domain"></v-text-field>
								</v-col>
								<v-col cols="2" v-if="(noEdition === false && userRole != 'client') || noEdition === false">
									<v-btn icon :loading="domainsExistLoad" :color="checkDomainExistColor"
										@click="checkDomainExist">
										<v-icon>mdi-check-bold</v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-autocomplete dense :items="items" :disabled="userRole == 'client' || noEdition" attach
								rounded outlined :menu-props="{ maxHeight: '180px' }" label="État" v-model="form.color">
								<template #selection="{ item }">
									<v-chip :color="getColor(item)" class="white--text"> {{ item }}</v-chip>
								</template>
							</v-autocomplete>
							<v-text-field dense label="Lien" :readonly="userRole == 'client' || noEdition" outlined rounded
								append-icon="mdi-open-in-new" @click:append="openLink()" v-model="form.link" />
							<v-text-field dense label="Extension" :disabled="userRole == 'client' || noEdition" outlined
								rounded v-model="form.ext" />
							<v-text-field dense label="Réf dossier" :disabled="userRole == 'client' || noEdition" outlined
								rounded v-model="form.ref_folder" />
						</v-col>

						<v-col cols="4" class="px-5">
							<v-subheader class="pl-0 pb-3">
								<v-icon small class="mr-2 primary--text grey lighten-3 pa-1" style="border-radius: 50%">
									mdi-cog
								</v-icon>
								Panneau de contrôle
							</v-subheader>

							<v-row class="pa-0 ma-0">
								<v-col cols="4" v-for="item in monitoring_options" :key="item.label">
									<v-switch :disabled="userRole == 'client' || noEdition" color="success" hide-details
										:label="item.label" v-model="form[item.key]" />
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="4" class="px-5">
							<v-subheader class="pl-0 pb-3">
								<v-icon small class="mr-2 primary--text grey lighten-3 pa-1" style="border-radius: 50%">
									mdi-message-text
								</v-icon>
								Informations complémentaires et Recommandations
							</v-subheader>
							<v-textarea outlined rows="3" :readonly="userRole == 'client'" :disabled="noEdition"
								label="Informations titulaire" v-model="form.holder" />

							<v-textarea label="Exploitation" :readonly="userRole == 'client'" :disabled="noEdition" outlined
								rows="3" v-model="form.usage" />
							<v-textarea filled rows="3" :readonly="userRole == 'client'" :disabled="noEdition"
								label="Recommandations scan" v-model="form.reco_scan" />

						</v-col>
					</v-row>

					<v-row class="mt-2">
						<v-col cols="4" class="px-5">
							<v-subheader class="pl-0 pb-3">
								<v-icon small class="mr-2 primary--text grey lighten-3 pa-1" style="border-radius: 50%">
									mdi-timer-sand
								</v-icon>
								Temporalité
							</v-subheader>

							<v-text-field class="pb-4" dense flat v-model="form.date_register"
								label="Date d'enregistrement (YYYY-MM-DD)" :rules="[
									(v) => !v || /^\d{4}-\d{2}-\d{2}$/.test(v) || 'La date doit être au format AAAA-MM-JJ'
								]" clearable :disabled="userRole == 'client' || noEdition" @click:clear="form.date_register = null" />
							<v-text-field class="pb-4" dense flat clearable v-model="form.date_expiration" :rules="[
								(v) => !v || /^\d{4}-\d{2}-\d{2}$/.test(v) || 'La date doit être au format AAAA-MM-JJ'
							]" label="Date d'expiration (YYYY-MM-DD)" :disabled="userRole == 'client' || noEdition"
								@click:clear="form.date_expiration = null" />

							<v-text-field dense flat clearable v-model="form.date_report" :rules="[
								(v) => !v || /^\d{4}-\d{2}-\d{2}$/.test(v) || 'La date doit être au format AAAA-MM-JJ'
							]" label="Date de signalement (YYYY-MM-DD)" :disabled="userRole == 'client' || noEdition"
								@click:clear="form.date_report = null" />
						</v-col>

						<v-col cols="4" class="px-5">
							<v-subheader class="pl-0 pb-3">
								<v-icon small class="mr-2 primary--text grey lighten-3 pa-1" style="border-radius: 50%">
									mdi-face-agent
								</v-icon>
								Instructions client
							</v-subheader>
							<v-textarea outlined rows="3" :readonly="userRole != 'client'" :disabled="noEdition"
								label="Instructions client" v-model="form.instruction" />
							<v-textarea dense flat outlined rows="3" :disabled="userRole == 'client' || noEdition"
								label="Correspondance" v-model="form.correspondance" />
						</v-col>

						<v-col cols="4" class="px-5">
							<v-subheader class="pl-0 pb-3">
								<v-icon small class="mr-2 primary--text grey lighten-3 pa-1" style="border-radius: 50%">
									mdi-folder-search
								</v-icon>
								Suivi de dossier
							</v-subheader>
							<v-textarea label="Suivi du dossier" outlined rows="3" :readonly="userRole == 'client'"
								:disabled="noEdition" v-model="form.follow" />
							<v-textarea label="Statut final" outlined rows="3" :readonly="userRole == 'client'"
								:disabled="noEdition" v-model="form.final" />
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>

import ButtonValidate from "@/components/buttons/ButtonValidate";
import ButtonCancel from "@/components/buttons/ButtonCancel";


import {
	VDialog,
	VCard,
	VSpacer,
	VRow,
	VCol,
	VTextField,
	VChip,
	VTextarea,
	VAutocomplete,
	VForm,
	VCardText,
} from "vuetify/lib";

export default {
	name: "EditDomain",
	components: {
		VDialog,
		VCard,
		VSpacer,
		VRow,
		VCol,
		VTextField,
		VChip,
		VTextarea,
		VAutocomplete,
		VForm,
		VCardText,
		ButtonValidate,
		ButtonCancel
	},
	props: {
		brand: String,
		dialog: Boolean,
		domain: Object,
		noEdition: Boolean
	},
	mounted() { },
	data() {
		return {
			form: null,
			rules: null,
			domainsExistLoad: false,
			domainsExist: null,
			items: [
				"Dossier en cours",
				"En attente d'instruction client",
				"Monitoring seule",
				"DEC - en attente d'instruction client",
				"NDD Client",
				"Signalement pour info",
			],
			value: [],
			monitoring_options: [
				{
					"label": "Monitoring",
					"key": "monitoring"
				},
				{
					"label": "Vérification client",
					"key": "check_client"
				},
				{
					"label": "Investigations SCAN",
					"key": "investigation"
				},
				{
					"label": "Levée d'anonymat",
					"key": "anonyme"
				},
				{
					"label": "Mise en demeure",
					"key": "notice"
				},
				{
					"label": "Notification",
					"key": "notif"
				},
				{
					"label": "Procédure extrajudiciaire",
					"key": "procedure_extra"
				},
				{
					"label": "Récupération à l'amiable",
					"key": "recovery"
				},
				{
					"label": "Evaluation financière",
					"key": "eval_financial"
				},
				{
					"label": "Backorder",
					"key": "backorder"
				},
				{
					"label": "Rachat",
					"key": "rebuy"
				},
				{
					"label": "UDRP",
					"key": "udrp"
				},
				{
					"label": "Syreli",
					"key": "syreli"
				},
				{
					"label": "PARL Expert",
					"key": "parl_expert"
				},
				{
					"label": "URS",
					"key": "urs"
				},
				{
					"label": "ADR",
					"key": "adr"
				},
				{
					"label": "CNDRP",
					"key": "cndrp"
				},
			]
		};
	},
	created() {
		this.editedDomain = this.domain;

		this.$api
			.get("/brands/" + this.brand + "/domains/" + this.domain.id, { params: { company_id: this.$store.state.company } })
			.then((res) => {
				/**
				 * Awfull fix
				 */
				if (res.data[0].date_register)
					res.data[0].date_register = this.formatDate(
						res.data[0].date_register
					);
				if (res.data[0].date_expiration)
					res.data[0].date_expiration = this.formatDate(
						res.data[0].date_expiration
					);
				if (res.data[0].date_report)
					res.data[0].date_report = this.formatDate(res.data[0].date_report);
				this.form = Object.assign({}, res.data[0]);
				this.adjustState();
			});
	},
	computed: {
		userRole() {
			return this.$store.state.user.role;
		},
		checkDomainExistColor() {
			if (this.domainsExist === false) return "green";
			if (this.domainsExist) return "red";
			return "grey";
		},
	},
	methods: {
		adjustState() {
			if (!this.form.color)
				return
			/**
			 * Awfull fix, need to find better solution.
			 */
			if (this.form.color === 'signalement pour info')
				this.form.color = "Signalement pour info";
		},
		openLink() {
			if (!this.form || !this.form.link) return;

			let url = this.form.link;

			if (!url.match(/^https?:\/\//i)) {
				url = 'http://' + url;
			}
			window.open(url, "_blank");
		},
		formatDate: function (item) {
			let date = new Date(item);
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let dt = date.getDate();

			if (dt < 10) {
				dt = "0" + dt;
			}
			if (month < 10) {
				month = "0" + month;
			}
			return year + "-" + month + "-" + dt;
		},
		getColor(item) {
			if (item) {
				item = item.toLowerCase();
				if (item == "dossier en cours") return "orange lighten-1";
				if (item == "en attente d'instruction client")
					return "deep-purple lighten-1";
				if (item == "monitoring seule") return "yellow lighten-1";
				if (item == "dec - en attente d'instruction client")
					return "blue lighten-1";
				if (item == "ndd client") return "green lighten-1";
				if (item == "signalement pour info") return "blue-grey lighten-1";
				return "grey lighten-1";
			}
			return "grey lighten-1";
		},
		saveDomain() {
			if (this.$refs.form.validate()) {
				this.formatDate(this.form);
				if (this.userRole == 'client') {
					this.putApi(
						"/brands/" + this.brand + "/domains/" + this.domain.id + "/inst",
						{ instruction: this.form.instruction, }
					)
				} else {
					this.putApi(
						"/brands/" + this.brand + "/domains/" + this.domain.id,
						this.form
					)
				}
			}
		},
		putApi(url, body) {
			this.$api
				.put(
					url,
					body
				)
				.then(() => {
					this.$emit("editDomain", "Saved!");
				})
				.catch((error) => {
					console.log("Error while updating", error);
				})
				.finally(() => {
					this.closeDomain();
					this.form = Object.assign({}, this.defaultForm);
				});

		},
		formatForm(form) {
			if (form.date_register)
				form.date_register = this.formatDate(form.date_register);
			if (form.date_expiration)
				form.date_expiration = this.formatDate(form.date_expiration);
			if (form.date_report)
				form.date_report = this.formatDate(form.date_report);
		},
		closeDomain() {
			if (!this.noEdition) {
				this.form = Object.assign({}, this.defaultForm);
			}
			this.$emit("closeDomain", "Closed!");
		},
		checkDomainExist() {
			this.domainsExistLoad = true;
			this.$api.get('/domains?s=' + this.form.domain)
				.then((res) => {
					if (res.data.rowCount === 1)
						this.domainsExist = true
					else
						this.domainsExist = false;

					setTimeout(() => {
						this.domainsExistLoad = false;
					}, 1000);
				})
		}
	},
};
</script>

<style lang="scss" scoped>
.little-bold-text {
	font-weight: 500;
}

::v-deep .v-label {
	font-size: 12px
}

::v-deep .v-label--active {
	font-size: 12px
}

::v-deep input {
	font-size: 12px
}

::v-deep .v-list-item__title {
	font-size: 12px
}

::v-deep .v-chip__content {
	font-size: 12px
}

::v-deep textarea {
	font-size: 12px
}
</style>