<template>
  <v-dialog v-model="createBrandToggle" width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-spacer></v-spacer>
        <v-card-title class="justify-center">
          {{ brand ? "Éditer une Marque" : "Nouvelle Marque" }}
        </v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-actions class="justify-center full-width">
        <v-row>
          <v-col cols="12" class="pb-0 mt-4">
            <v-form v-if="form" ref="form" lazy-validation>
              <v-text-field
              filled
              rounded
              v-model="form.name"
              label="NOM"
              :rules="rulesLogin"
              append-icon="mdi-pencil"
              v-on:keyup.enter="createNewBrand"/>

              <v-file-input
              v-model="form.img"
              @change="onFileInput($event)"
              :rules="[!bigFile || 'Fichier trop volumineux, 65 Ko max']"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Choisir un logo"
              append-icon="mdi-image-filter-hdr" prepend-icon="" rounded filled
              label="Logo de la marque"/>

              <div class="text-center pb-5" v-if="form.img">
                <img :src="logo" height="100px" align="center"/>
              </div>

              <v-textarea
              filled rounded rows="3" hide-details
              label="Commentaire"
              append-icon="mdi-comment-text-outline"
              v-model="form.comment"/>

              <v-tooltip bottom max-width="255px" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-switch
                    v-if="$store.state.user.role == 'admin' && !brand"
                    color="#17B07B" hide-details small
                    label="Validation administrateur"
                    v-model="form.manual_validation"/>
                  </span>
                </template>
                <span>
                  Lorsque la validation administrateur est activée chaque création de fiche de domaine
                  devra toujours être validée par un administrateur pour être visible dans le tableau de Surveillance.
                </span>
              </v-tooltip>
<!-- 
              <v-switch
              v-if="brand"
              class="ml-5"
              style="font-weight: 500 !important;"
              color="red"
              v-model="form.archived"
              label="Archiver la marque">
                {{ form.archived }}
              </v-switch> -->
            </v-form>
          </v-col>

          <v-col cols="12" align="center" class="pt-5 mb-2">
            <ButtonCancel
            v-on:clicked="closeNewBrand"/>
            <ButtonValidate
            v-on:clicked="createNewBrand"/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonValidate from "@/components/buttons/ButtonValidate";
import ButtonCancel from "@/components/buttons/ButtonCancel";


import {
  VDialog,
  VCard,
  VToolbar,
  VSpacer,
  VDivider,
  VCardActions,
  VRow,
  VCol,
  VForm,
  VTextField,
  VCardTitle,
} from "vuetify/lib";

export default {
  name: "BrandCreateUpdate",
  components: {
    VDialog,
    VCard,
    VToolbar,
    VSpacer,
    VDivider,
    VCardActions,
    VRow,
    VCol,
    VForm,
    VTextField,
    VCardTitle,
	ButtonValidate,
	ButtonCancel
  },
  props: {
    createBrandToggle: Boolean,
    brand: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    createBrandToggle(newValue, oldValue) {
      if (newValue == false && oldValue == true) {
        this.$refs.form.resetValidation();
      }
    },
  },
  created() {
    if (this.brand) {
      this.init_brand();
    }
  },
  data() {
    const defaultForm = Object.freeze({
      name: null,
      img: null,
      comment: null,
    });
    return {
      form: Object.assign({}, defaultForm),
      logo: null,
      bigFile: false,
    };
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    },
    init_brand() {
      this.logo = 'data:image/png;base64,' + this.brand.img;
      let file = this.dataURLtoFile(this.logo, "image.png");

      this.form = {
        name: this.brand.name,
        img: this.brand.img ? file : null,
        comment: this.brand.comment,
        archived: this.brand.archived,
      }
    },
    onFileInput(event) {
      if (event) {
        const data = URL.createObjectURL(event);
        this.logo = data;
      }
      this.getBase64(this.form.img)
        .then(() => {
          this.bigFile = false;
        })
        .catch(() => {
          this.bigFile = true;
        });
    },
    async getBase64(file) {
      if (!file) {
        return null;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise((resolve, reject) => {
        reader.onload = function () {
          let base64 = /,(.+)/.exec(reader.result)[1];
          if (base64.length > 65535) {
            return reject("Too Large");
          }
          return resolve(base64);
        };
        reader.onerror = function () {
          reject("NO");
        };
      })
    },
    createNewBrand() {
      if (this.$refs.form.validate()) {
        if (this.brand) {
          this.getBase64(this.form.img).then((data) => {
            this.$api
              .put("/brands/" + this.brand.id, {
                name: this.form.name,
                img: data,
                comment: this.form.comment,
                company_id: this.$store.state.company,
              })
              .then(() => {
                this.$emit("success");
              })
              .catch(() => {
                this.$emit("fail");
              }).finally(() => {
                this.bigFile = false;
              })
          }).catch((error) => {
            this.bigFile = true;
            console.log(error);
          });
        } else {
          this.getBase64(this.form.img).then((data) => {
            this.$api
              .post("/brands", {
                name: this.form.name,
                img: data,
                comment: this.form.comment,
                company_id: this.$store.state.company,
                manual_validation: this.form.manual_validation ? true : false,
              })
              .then(() => {
                this.$emit("success");
              })
              .catch(() => {
                this.$emit("fail");
              })
              .finally(() => {
                this.bigFile = false;
                this.form = Object.assign({}, this.defaultForm);
              });
          }).catch(() => {
            this.bigFile = true;
          });
        }
      }
    },
    closeNewBrand() {
      if (!this.brand) {
        this.form = Object.assign({}, this.defaultForm);
      } else {
        this.init_brand();
      }
      this.$emit("close");
    },
  },
  computed: {
    rulesLogin() {
      const rules = [];

      const notEmpty = (v) => (v || "").length > 0 || "Champ requis";
      const validLogin = (v) =>
        /^[a-z\d\-_\s]+$/i.test(v) || "Character valide a-z A-Z 0-9";
      rules.push(notEmpty);
      rules.push(validLogin);
      return rules;
    },
  },
};
</script>
