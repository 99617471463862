<template>
    <v-snackbar v-model="$store.state.snackbar.model" timeout="2000" :color="$store.state.snackbar.status">
        {{ $store.state.snackbar.msg }}
    </v-snackbar>
</template>

<script>
export default {
    name: "Snackbar",
}
</script>