<template>
  <v-app-bar hide-on-scroll app style="right: 0px; width: 100%; left: 0px" elevation="1">
    <v-row>
      <v-col cols="2" class="d-flex align-center"></v-col>
      <!-- <v-spacer></v-spacer> -->
      <v-col class="d-flex justify-space-around align-center">
        <v-toolbar-title
          class="text-center pr-16 font-weight-bold mouse-click"
          @click="goBase"
        >
          <v-img
            class="mx-auto"
            src="@/assets/Logo_PNG2.png"
            max-height="100"
            max-width="150"
          >
          </v-img>
        </v-toolbar-title>
      </v-col>
      <!-- <v-spacer></v-spacer> -->
      <v-col cols="2" class="d-flex justify-end align-center">
        <v-row justify="end" class="pr-2">
          <v-menu class="mt-4" bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <h4 class="d-flex pr-4 align-center">{{ login }}</h4>
              <v-btn color="primary" icon small outlined v-bind="attrs" v-on="on">
                <v-icon small>fas fa-user</v-icon>
              </v-btn>
              <!-- </v-btn> -->
            </template>
            <v-list dense>
              <v-list-item
                class="text-left mb-2"
                v-for="(item, index) in items"
                :key="index"
              >
                <v-btn @click="item.target" depressed>
                  {{ item.title }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import {
  VIcon,
  VCol,
  VBtn,
  VListItem,
  VList,
  VMenu,
  VToolbarTitle,
  VImg,
  VAppBar,
  VRow,
} from "vuetify/lib";

export default {
  components: {
    VListItem,
    VBtn,
    VList,
    VCol,
    VIcon,
    VMenu,
    VAppBar,
    VToolbarTitle,
    VImg,
    VRow,
  },
  data() {
    return {
      id: null,
      items: [
        // { title: 'Paramètres', target: this.paramUser},
        { title: "Déconnexion", target: this.logout },
      ],
      login: null,
    };
  },
  created() {
    this.login = this.$store.getters.getLogin;
    this.id = this.$store.getters.getId;
  },
  methods: {
    paramUser() {
      this.$router.push("/user/" + this.id);
    },
    logout() {
      this.$store.dispatch("logout", this.id).then(() => {
        this.$router.push("/");
      });
    },
    goBase() {
      this.$router.push("/companies").catch(() => {});
    },
  },
};
</script>

<style>
.mouse-click:hover {
  cursor: pointer;
}
</style>