<template>
  <v-dialog v-model="createCompanyToggle" width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-spacer></v-spacer>
        <v-card-title class="justify-center"> 
          {{ company ? "Éditer une Entreprise" : "Nouvelle Entreprise" }}
        </v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-actions class="justify-center full-width">
        <v-row>
          <v-col cols="12" class="pb-0 mt-4">
            <v-form v-if="form" ref="form" lazy-validation>
              <v-text-field
              filled
              rounded
              v-model="form.name"
              label="NOM"
              :rules="rulesLogin"
              append-icon="mdi-pencil"
              v-on:keyup.enter="createNewCompany"/>

              <v-file-input
              v-model="form.img"
              @change="onFileInput($event)"
              :rules="[!bigFile || 'Fichier trop volumineux, 65 Ko max']"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Choisir un logo"
              append-icon="mdi-image-filter-hdr" prepend-icon="" rounded filled
              label="Logo de l'entreprise"/>

              <div class="text-center pb-5" v-if="form.img">
                <img :src="logo" height="100px" align="center"/>
              </div>

              <v-textarea
              filled rounded hide-details rows="3" class="mb-3"
              label="Commentaire"
              append-icon="mdi-comment-text-outline"
              v-model="form.comment"/>

              <v-switch
              v-if="company"
              class="ml-5"
              style="font-weight: 500 !important;"
              color="red"
              v-model="form.archived"
              label="Archiver l'entreprise">
                {{ form.archived }}
              </v-switch>
            </v-form>
          </v-col>

          <v-col cols="12" align="center" class="pt-0 mb-2">
            <ButtonCancel
            v-on:clicked="closeNewCompany"/>
            <ButtonValidate
            v-on:clicked="createNewCompany"/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonValidate from "@/components/buttons/ButtonValidate";
import ButtonCancel from "@/components/buttons/ButtonCancel";
import MapStore from "@/mixins/MapStore";

import {
  VDialog,
  VCard,
  VToolbar,
  VSpacer,
  VDivider,
  VCardActions,
  VRow,
  VCol,
  VForm,
  VTextField,
  VCardTitle,
} from "vuetify/lib";

export default {
  name: "CompanyCreateUpdate",
  components: {
    VDialog,
    VCard,
    VToolbar,
    VSpacer,
    VDivider,
    VCardActions,
    VRow,
    VCol,
    VForm,
    VTextField,
    VCardTitle,
    ButtonValidate,
    ButtonCancel
  },
  mixins: [ MapStore ],
  props: {
    createCompanyToggle: Boolean,
    company: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    createCompanyToggle(newValue, oldValue) {
      if (newValue == false && oldValue == true) {
        this.$refs.form.resetValidation();
      }
    },
  },
  created() {
    if (this.company) {
      this.init_company();
    }
  },
  data() {
    const defaultForm = Object.freeze({
      name: null,
      img: null,
      comment: null,
    });
    return {
      form: Object.assign({}, defaultForm),
      logo: null,
      bigFile: false,
    };
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    },
    init_company() {
      this.logo = 'data:image/png;base64,' + this.company.img;
      let file = this.dataURLtoFile(this.logo, "image.png");

      this.form = {
        name: this.company.name,
        img: this.company.img ? file : null,
        comment: this.company.comment,
        archived: this.company.archived,
      }
    },
    onFileInput(event) {
      if (event) {
        const data = URL.createObjectURL(event);
        this.logo = data;
      }

      this.getBase64(this.form.img)
        .then(() => {
          this.bigFile = false;
        })
        .catch(() => {
          this.bigFile = true;
        });
    },
    async getBase64(file) {
      if (!file) {
        return null;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve, reject) => {
        reader.onload = function () {
          let base64 = /,(.+)/.exec(reader.result)[1];
          if (base64.length > 65535) {
            reject("Too Large");
          }
          resolve(base64);
        };
        reader.onerror = function () {
          reject("NO");
        };
      })
    },
    createNewCompany() {
      if (this.$refs.form.validate()) {
        this.getBase64(this.form.img).then((data) => {
          let method = this.company ? 'put' : 'post';
          let id = this.company ? this.company.id : null;

          this.$store.dispatch(method,
          {
            route: this.api.companies,
            id: id,
            data: {
              name: this.form.name,
              img: data,
              comment: this.form.comment
            }
          }).then(() => {
            this.$emit("success");
          }).catch(() => {
            this.$emit("fail");
          }).finally(() => {
            this.bigFile = false;
            if (!this.company) {
              this.form = Object.assign({}, this.defaultForm);
            }
          });
        }).catch(() => {
          this.bigFile = true;
        });
      }
    },
    closeNewCompany() {
      if (!this.company) {
        this.form = Object.assign({}, this.defaultForm);
      } else {
        this.init_company();
      }
      this.$emit("close");
    },
  },
};
</script>
