<template>
    <v-btn
        v-if="condition" dark fab small color="green"
        class="ma-1"
        @click="$emit('clicked')">
            <v-icon small> fas fa-plus </v-icon>
        </v-btn>
</template>

<script>
export default {
    name: "ButtonPlus",
    props: {
        condition: {
            type: Boolean,
            required: true
        }
    }
}
</script>
